import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3)
  },
  title: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left',
    color: '#000000'
  },
  intro: {
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    marginRight: theme.spacing(4),
    color: '#0C0C0C'
  },
  grid: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  flex: {
    //alignItems: "center",
    display: "flex"
  },
  icons: {
    display: "grid",
    gridTemplateColumns: "min-content min-content min-content"
  },
  iconWrapper: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "center",
    minWidth: "64px"
  },
  icon: {
    color: blue[800],
    fontSize: "3rem"
  },
  dot: {
    height: '30px',
    width: '30px',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1,
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
    //borderWidth: 3,
    //borderStyle: 'inset'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  amber: {
    color: amber[500]
  },
  red: {
    color: red[300]
  },
  risk: {
    borderTop: "1px solid black",
    fontSize: "10px",
    paddingTop: theme.spacing(3),
    position: "relative",
    textAlign: "center"
  },
  riskMark: {
    backgroundColor: "black",
    height: "20px",
    left: "50%",
    position: "absolute",
    top: "-10px",
    width: "1px"
  },
  detailIntro: {
    marginLeft: theme.spacing(11),
    marginTop: theme.spacing(2),
    font: "Bold 14px/22px Roboto",
    float: 'left',
  },
  separator: {
    paddingTop: theme.spacing(1),
    width: '100%',
    borderTop: '1px solid #CCCCCC',
  },
  detail: {
    font: "Normal 14px/22px Roboto",
    color: '#000000',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    marginRight: theme.spacing(12),
    marginLeft: theme.spacing(11)
  }
}));

export default function EmploymentRisk({ data, risk }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    S0001_companyName: companyName,
    S0010_domesticContractWorkers: domesticContractWorkers,
    S0011_foreignContractWorkers: foreignContractWorkers,
    S0012_recruitmentAgencies: recruitmentAgencies
  } = data;

  let riskLevel = "low";
  if (
    (domesticContractWorkers === "Yes" && foreignContractWorkers === "Yes") ||
    ((domesticContractWorkers === 'Yes') || (foreignContractWorkers === 'Yes')) && recruitmentAgencies === 'Yes'
  ) {
    riskLevel = "high";
  } else if (
    domesticContractWorkers === "Yes" ||
    foreignContractWorkers === "Yes" ||
    recruitmentAgencies === "Yes"
  ) {
    riskLevel = "medium";
  }

  function renderRecruitmentText() {
    if (recruitmentAgencies !== "Yes") return null;

    return (
      <React.Fragment>
      <Grid container >
      <Grid item className={classes.separator}>
      <Typography className={classes.detailIntro}>
        You have indicated that your company uses recruitment agencies.
      </Typography>
      </Grid>
      <Grid item>
      <Typography className={classes.detail}>
        Using recruitment agencies is a legitimate business practice but can introduce
        additional risks to workers due to a lack of transparency of recruitment
        practices. Common practices facilitating modern slavery include the
        charging of recruitment fees and the withholding of identification
        documents.
      </Typography>
      </Grid>
      </Grid>
    </React.Fragment>
    );
  }


  function renderWorkers() {
    // scenario 1
    if (domesticContractWorkers === "Yes" && foreignContractWorkers === "Yes") {
      return (
        <React.Fragment>
          <Grid container className={classes.separator}>
            <Grid item >
              <Typography className={classes.detailIntro}>
                You have stated that {companyName} employs domestic and foreign
                contract workers.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detail}>
                Companies employing domestic and foreign contract workers are
                encouraged to conduct due diligence to analyze and mitigate
                risks. Contract workers may be less well protected by
                labour laws, may lack adequate representation, have less
                access to adequate remedies and often face discrimination.
                Foreign contract workers may be less aware of their rights and
                are at an even greater risk of exploitation than domestic
                contract workers.
              </Typography>
            </Grid>
              <Grid item>
                {renderRecruitmentText()}
              </Grid>
            </Grid>
        </React.Fragment>
      );
    }

    // scenario 2a
    if (domesticContractWorkers === "Yes") {
      return (
        <React.Fragment>
          <Grid container className={classes.separator}>
            <Grid item >
              <Typography className={classes.detailIntro}>
                You have stated that {companyName} employs domestic contract
                workers.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detail}>
                Companies employing domestic contract workers are encouraged
                to operate with particular care and to conduct due diligence
                to mitigate risks. Domestic contract workers may be
                less well protected by labour laws, may lack adequate
                representation, have less access to adequate remedies and
                often face discrimination.
              </Typography>
            </Grid>
            <Grid item>
              {renderRecruitmentText()}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    // scenario 2b
    if (foreignContractWorkers === "Yes") {
      return (
        <React.Fragment>
          <Grid container className={classes.separator}>
            <Grid item >
              <Typography className={classes.detailIntro}>
                You have stated that {companyName} employs foreign contract
                workers.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detail}>
                Companies employing foreign contract workers are encouraged to
                operate with particular care and to conduct due diligence to
                mitigate worker risks. Foreign contract workers may be less
                aware of their rights and are at an even greater risk of
                exploitation than domestic contract workers. Foreign contract
                workers may be less well protected by labour laws, may lack
                adequate representation and access to remedy and often face
                discrimination and differences in treatment.
              </Typography>
            </Grid>
            <Grid item>
              {renderRecruitmentText()}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    // scenario 3
    return (
      <React.Fragment>
      <Grid container className={classes.separator}>
        <Grid item >
          <Typography className={classes.detailIntro}>
              You have stated that {companyName} employs neither domestic nor
              foreign contract workers.
            </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.detail}>
                Domestic and foreign contract workers are more vulnerable to
                exploitation. These workers are typically less aware of their
                rights, less protected by labour laws, lack adequate
                representation and access to legal remedies and often face
                discrimination.
              </Typography>
              </Grid>
              <Grid item>
                {renderRecruitmentText()}
              </Grid>
            </Grid>
      </React.Fragment>
    );
  }

  return (
    <ExpansionPanel defaultExpanded>
    <ExpansionPanelSummary
      expandIcon={expanded === false ? <RemoveIcon fontSize="large" fontWeight="bold"/> : <AddIcon fontSize="large" fontWeight="bold"/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      onClick={handleExpandClick}
    >
    <Grid container >
      <Grid container >
        <Grid item xs={1} >
          <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
        </Grid>
        <Grid item xs={11} >
          <Typography className={classes.title} component="h4" variant="h5">
            Your employment risk
          </Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={1}/><Grid item xs={11} >
          <Typography className={classes.intro} variant="body2">
            Some groups of workers are more vulnerable to exploitation and labour
            rights abuses. Particularly vulnerable workers include migrants,
            indigenous people, marginalized groups, women and seasonal or
            low-skilled workers. These worker groups are often employed in
            labour-intensive and competitive industries with low barriers to entry.
            Human rights due diligence requires businesses to pay special attention
            to such vulnerable groups.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ExpansionPanelSummary>
      {renderWorkers()}
    </ExpansionPanel>
  );
}
