import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ErrorMessage } from "formik";
import React from "react";

function CustomRadio({
  name,
  label,
  helperText,
  values,
  errors,
  touched,
  onChange
}) {
  return (
    <FormControl
      margin="normal"
      fullWidth={true}
      required={true}
      error={errors[name] && touched[name] ? true : false}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={values[name]} onChange={onChange}>
        <FormControlLabel
          value="No"
          control={<Radio color="primary" />}
          label="No"
        />
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" />}
          label="Yes"
        />
      </RadioGroup>
      <ErrorMessage name={name}>
        {errorMessage => <FormHelperText>{errorMessage}</FormHelperText>}
      </ErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default CustomRadio;
