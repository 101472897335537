import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { optionsDictionary } from "../data/options";
import { GlobalStateContext } from "../lib/globalState";
import ConfirmDialog from "./ConfirmDialog";

const useStyles = makeStyles(theme => ({
  content: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
    padding: theme.spacing(3)
  },
  answer: {
    marginBottom: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(0, 0.75)
  }
}));

function ReviewData({ history, config, handleBack }) {
  const classes = useStyles();
  const [globalState, dispatch] = useContext(GlobalStateContext);
  const { values } = globalState;
  const questions = config.sections.map(section => section.questions).flat();
  const [modalOpen, setModalOpen] = useState(false);

  function handleGenerateDashboard() {
    // change the isDashboardGenerated flag in state
    dispatch({ type: "GENERATE_DASHBOARD" });
    history.push("/dashboard");
  }

  function handleModalOpen() {
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  function renderData() {
    return (
      <Paper className={classes.content}>
        {questions.map(question => {
          let answer = values[question.name];

          // special case to hide Q20 when it is "N/A"
          if (answer.includes("N/A")) {
            return null;
          }

          // NB an array is actually an object
          if (typeof answer === "object") {
            answer = answer.map(answer => optionsDictionary[answer]).join(", ");
          }

          // show option name rather than value
          if (/option_/.test(answer)) {
            answer = optionsDictionary[answer];
          }

          return (
            <React.Fragment key={question.id}>
              <Typography gutterBottom variant="body1">
                {question.label}
              </Typography>
              <Typography className={classes.answer} variant="h6">
                {answer}
              </Typography>
            </React.Fragment>
          );
        })}
      </Paper>
    );
  }

  return (
    <React.Fragment>
      <Typography
        gutterBottom
        component="h2"
        variant="subtitle1"
        align="center"
      >
        Please review the data below before you generate your dashboard.
      </Typography>
      {values && questions ? (
        renderData()
      ) : (
        <Typography variant="body1" align="center">
          No data found. Please go back and submit the form again.
        </Typography>
      )}
      <Button className={classes.button} onClick={handleBack}>
        Back
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleModalOpen}
      >
        Generate Dashboard
      </Button>
      <ConfirmDialog
        open={modalOpen}
        handleNo={handleModalClose}
        handleYes={handleGenerateDashboard}
      />
    </React.Fragment>
  );
}

export default withRouter(ReviewData);
