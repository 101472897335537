import React from "react";

export default function ExternalLink({ type }) {
  switch (type) {
    case "GB": {
      return (
        <a
          href="http://www.legislation.gov.uk/ukpga/2015/30/part/6/enacted"
          rel="noopener noreferrer"
          target="_blank"
        >
          UK Modern Slavery Act 
        </a>
      );
    }
    case "FR": {
      return (
        <a
          href="http://www.assemblee-nationale.fr/14/pdf/ta/ta0924.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          French Corporate Duty of Vigilance Law
        </a>
      );
    }
    case "US": {
      return (
        <a
          href="https://www.acquisition.gov/sites/default/files/current/far/pdf/FAR.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Federal Acquisition Regulation
        </a>
      );
    }
    case "CA": {
      return (
        <a
          href="https://oag.ca.gov/sites/all/files/agweb/pdfs/cybersafety/sb_657_bill_ch556.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          California Transparency in Supply Chains Act
        </a>
      );
    }
    case "AU": {
      return (
        <a
          href="https://www.legislation.gov.au/Details/C2018A00153"
          rel="noopener noreferrer"
          target="_blank"
        >
          Australian Modern Slavery Act
        </a>
      );
    }
    case "NSW": {
      return (
        <a
          href="https://www.legislation.nsw.gov.au/#/view/act/2018/30"
          rel="noopener noreferrer"
          target="_blank"
        >
          New South Wales Modern Slavery Act
        </a>
      );
    }
    default: {
      return null;
    }
  }
}
