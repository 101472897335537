import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { withFirebase } from "../lib/firebase";
import CustomInput from "./CustomInput";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 4, 4)
  },
  button: {
    marginTop: theme.spacing(3)
  },
  response: {
    marginTop: theme.spacing(3),
    font: '14px/22px Roboto',
    fontWeight: 'light'
  }
}));

const initialValues = {
  passwordOne: "",
  passwordTwo: ""
};

const validationSchema = Yup.object().shape({
  passwordOne: Yup.string()
    .required("Required")
    .min(8, "Must be at least 8 characters"),
  passwordTwo: Yup.string()
    .required("Required")
    .test("passwords-match", "Passwords must match", function(value) {
      return this.parent.passwordOne === value;
    })
});

function SignUpFormBase({ firebase }) {
  const classes = useStyles();
  const [firebaseSuccess, setFirebaseSuccess] = useState(null);
  const [firebaseError, setFirebaseError] = useState(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        const { passwordOne } = values;
        firebase
          .doPasswordUpdate(passwordOne)
          .then(() => {
            resetForm();
            setFirebaseSuccess({
              message: "Password successfully changed."
            });
          })
          .catch(error => {
            setFirebaseError(error);
          });
      }}
    >
      {({ isSubmitting, values, errors, touched, handleChange }) => (
        <Paper className={classes.wrapper}>
          <Form noValidate>
            <CustomInput
              id="passwordOne"
              name="passwordOne"
              label="New Password"
              type="password"
              autoComplete="new-password"
              helperText="Must be at least 8 characters"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
            <CustomInput
              id="passwordTwo"
              name="passwordTwo"
              label="Confirm New Password"
              type="password"
              autoComplete="new-password"
              helperText="Must be at least 8 characters"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Change password
            </Button>
            {firebaseSuccess && (
              <FormHelperText className={classes.response}>
                {firebaseSuccess.message}
              </FormHelperText>
            )}
            {firebaseError && (
              <FormHelperText error className={classes.response}>
                {firebaseError.message}
              </FormHelperText>
            )}
          </Form>
        </Paper>
      )}
    </Formik>
  );
}

const SignUpForm = withFirebase(SignUpFormBase);

export default SignUpForm;
