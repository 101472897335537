import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import AuthFormWrapper from "../components/AuthFormWrapper";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(4, 0)
  },
  content: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    textAlign: "center"
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

export default function EmailVerificationPage({
  isSent,
  onSendEmailVerification
}) {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          align="center"
          color="textPrimary"
        >
          Email verification required
        </Typography>
        <AuthFormWrapper>
          <Paper className={classes.content}>
            {isSent ? (
              <Typography>
                Email confirmation sent. Please check your inbox for a
                confirmation email from us.
              </Typography>
            ) : (
              <Typography>
                First we need to verify your your email address. Please check
                your inbox for a confirmation email from us.
              </Typography>
            )}
            <Button
              type="button"
              className={classes.button}
              onClick={onSendEmailVerification}
              disabled={isSent}
              variant="contained"
              color="primary"
            >
              Resend confirmation Email
            </Button>
          </Paper>
        </AuthFormWrapper>
      </Container>
    </main>
  );
}
