import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as Yup from "yup";
import { withFirebase } from "../lib/firebase";
import CustomInput from "./CustomInput";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 4, 4)
  },
  button: {
    marginTop: theme.spacing(3)
  },
  error: {
    marginTop: theme.spacing(3)
  }
}));

const initialValues = {
  email: "",
  password: ""
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email(),
  password: Yup.string().required("Required")
});

function LoginFormBase({ firebase, history }) {
  const classes = useStyles();
  const [firebaseError, setFirebaseError] = useState(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        const { email, password } = values;
        firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            resetForm();
            history.push("/");
          })
          .catch(error => {
            setFirebaseError(error);
          });
      }}
    >
      {({ isSubmitting, values, errors, touched, handleChange }) => (
        <Paper className={classes.wrapper}>
          <Form noValidate>
            <CustomInput
              id="email"
              name="email"
              label="Email Address"
              type="text"
              autoComplete="email"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
            <CustomInput
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Log In
            </Button>
            {firebaseError && (
              <FormHelperText error className={classes.error}>
                {firebaseError.message}
              </FormHelperText>
            )}
          </Form>
        </Paper>
      )}
    </Formik>
  );
}

const LoginForm = compose(withRouter, withFirebase)(LoginFormBase);

export default LoginForm;
