import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import ComplianceCard from "../components/ComplianceCard";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left',
    color: '#000000'
  },
  intro: {
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    marginRight: theme.spacing(4),
    color: '#0C0C0C',
    letterSpacing: 0,
  },
  dot: {
    height: '30px',
    width: '30px',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1,
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
    //borderWidth: 3,
    //borderStyle: 'inset'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  subtitle: {
    alignItems: "center",
    display: "flex"
  },
  box: {
    border: `1px solid ${theme.palette.text.primary}`
  },
  orange: {
    backgroundColor: orange[100]
  },
  green: {
    backgroundColor: green[100]
  },
  red: {
    backgroundColor: red[100]
  },
  row: {
    alignItems: "center",
    display: "flex"
  },
  popperList: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  popperListitem: {
    marginTop: theme.spacing(1)
  }
}));


export default function PoliciesPracticesSummary({ data, risk }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    S0013_codeOfConduct: codeOfConduct,
    S0014_forcedLabourPolicy: forcedLabourPolicy,
    S0015_recruitmentPolicy: recruitmentPolicy,
    S0016_modernSlaveryStatement: modernSlaveryStatement,
    S0017_inductionProgram: inductionProgram,
    S0018_generalTraining: generalTraining,
    S0019_speakUpSystem: speakUpSystem,
    S0020_speakUpQualities: speakUpQualities,
    S0021_internalAudits: internalAudits
  } = data;

  //align risk matrix with numeric order of questions NB this may differ from
  //layout in form!!! Default risks to yellow === 0
  var complianceRisk = [0,0,0,0,0,0,0,0];
  if (codeOfConduct === "No") { complianceRisk[0] = 2; }
  if (forcedLabourPolicy === "No") { complianceRisk[1] = 2; }
  if (recruitmentPolicy === "No") { complianceRisk[2] = 2; }
  if (modernSlaveryStatement === "No") { complianceRisk[3] = 2; }
  if (inductionProgram === "No") { complianceRisk[4] = 2; }
  if (generalTraining === "No") { complianceRisk[5] = 2; }
  (speakUpSystem === "Yes" && speakUpQualities.length !== 3) ?
    complianceRisk[6] = 1 :
      (speakUpSystem === "No") ?
      complianceRisk[6] = 2:
      complianceRisk[6] = 0;
  if (internalAudits === "No") { complianceRisk[7] = 2; }

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={expanded === false ? <RemoveIcon fontSize="large" fontWeight="bold"/> : <AddIcon fontSize="large" fontWeight="bold"/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleExpandClick}
      >
        <Grid container >
          <Grid container >
            <Grid item xs={1} >
              <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
            </Grid>
            <Grid item xs={11} >
              <Typography className={classes.title} component="h4" variant="h5">
                Your policies and practices
              </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={1}/>
            <Grid item xs={11} >
              <Typography className={classes.intro} variant="body2">
                Implementing the right policies and practices is essential for tackling
                modern slavery. Clear communication of your company’s ethical standards
                and expectations is necessary to provide both your employees and
                business partners with the guidance needed for managing modern slavery
                risk. Due diligence additionally requires the implementation of robust
                monitoring and control systems to assess the effectiveness of your
                policy framework.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
        <ComplianceCard risk={complianceRisk} />
    </ExpansionPanel>
  );
}
