import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import AuthFormWrapper from "../components/AuthFormWrapper";
import SignUpForm from "../components/SignUpForm";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(4, 0)
  }
}));

function SignupPage() {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          align="center"
          color="textPrimary"
        >
          Sign Up
        </Typography>
        <AuthFormWrapper>
          <SignUpForm />
        </AuthFormWrapper>
        <Typography variant="subtitle1" align="center">
          Already have an account?{" "}
          <Link component={RouterLink} color="primary" to="/login">
            Log In
          </Link>
        </Typography>
      </Container>
    </main>
  );
}

export default SignupPage;
