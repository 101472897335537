import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
  MuiPaper: {
      root: {
        borderColor: 'red',
      }
  },
  box: {
    display: "flex",
    border: "1px solid #CCCCCC",
    fontWeight: "bold",
    height: 112,
    width: 191,
    padding: 0,
    textAlign: "center",
    //borderWidth: 1,
    //borderColor: grey[300],
    borderRadius: '2%',
    //borderRightColor: 'red',
    "&:hover": {
        fill: "#4276DC",
        color: "#4276DC",
        opacity: 0.8,
        borderColor: "#4276DC",
        boxShadow: 'none'
      },
  },
  container: {
    justifyContent: 'center',
  },
  comply: {
    backgroundColor: red[100]
  },
  dot: {
    height: '22px',
    width: '22px',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
    //borderWidth: 3,
    //borderStyle: 'inset'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  text: {
    textAlign: 'center',
    font: 'Bold 14px Roboto',
    letterSpacing: 0,
    color: '#303030',
    opacity: 0.8
  },
  media: {
    //height: 50,
    //maxWidth: 50,
    //paddingTop: 0,
    //paddingBottom: theme.spacing(1),
    fill: '#4276DC',
    //top: '371px',
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  frame: {
    background: 'darkgrey',
    width: 300
  }
}));

export default function ComplySummary({ id, risk, text, image }) {
  const classes = useStyles();

  return (
    <Paper elevation={2} border={1} className={classes.box}>
    <Grid container spacing={0}>
      <Grid item xs={3}>
        <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
      </Grid>
      <Grid item xs={9}>
          <CardMedia
            className={classes.media}
            component="img"
            image={image}
          />
          </Grid>
      <Grid item xs={12}>
        <Typography className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
  );

}
