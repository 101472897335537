import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ExternalLink from "./ExternalLink";
import icon from '../img/icon-aus.svg';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  list: {
    //paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    //textAlign: 'left',
      },
  listItem: {
    font: '14px/22px Roboto',
    marginLeft: theme.spacing(-3),
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left'
  },
  iconImage: {
    opacity: 1,
    marginLeft: theme.spacing(3),
  },
  iconImageDimmed: {
    opacity: 0.2,
    marginLeft: theme.spacing(3),
  },
  title: {
    font: 'Bold 14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left',
  },
  text: {
    marginLeft: 0,
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(0),
    textAlign: 'justify',
    paddingTop: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    marginLeft: theme.spacing(0),//marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  additionalInfo: {
    textAlign: "left",
    font: "16px/57px Roboto",
    letterSpacing: 0,
    color: "#DF452D",
    maxWidth: "300px",
    paddingRight: "16px",
    display: "flex",
    marginRight: theme.spacing(8),
  },
  cardContent: {
    paddingTop: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: theme.spacing(12),
  },
  collapse: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  }
}));

export default function ReportingObligationsAU({
  companyName,
  scenario,
  isAURegistered,
  hasAUOperations,
  isOverAUTurnoverThreshold
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };

  if (scenario === 2) return null;

  // outcome 1
  if (!isAURegistered && !hasAUOperations && !isOverAUTurnoverThreshold) {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="Australian jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
      <div><Typography className={classes.title}>
        Australia
      </Typography>
      <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="AU" /> but is encouraged to implement due
            diligence measures to mitigate slavery risks within the company’s supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 2
  if (!isAURegistered && !hasAUOperations && isOverAUTurnoverThreshold) {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="Australian jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
      <div><Typography className={classes.title}>
        Australia
      </Typography>
      <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="AU" /> as it does not carry on business in
            Australia. It is encouraged however to implement due diligence
            measures to mitigate modern slavery risks within the company’s supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 3
  if ((isAURegistered || hasAUOperations) && !isOverAUTurnoverThreshold) {
    return (
        <React.Fragment>
        <div>
        <img src={icon} alt="Australian jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
        <div><Typography className={classes.title}>
          Australia
        </Typography>
        <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="AU" /> as it does not meet the law’s revenue
            threshold, but {companyName} may be subject to the forthcoming{" "}
            <ExternalLink type="NSW" />.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 4
  if ((isAURegistered || hasAUOperations) && isOverAUTurnoverThreshold) {
    return (
        <React.Fragment>
          <div>
            <img src={icon} alt="Australian jurisdiction" width="44" className={classes.iconImage}/></div>
              <div>
                <Typography className={classes.title}>
                  Australia
                </Typography>
                <Grid container >
                  <Grid item >
                    <Typography className={classes.text} variant="body1">
            It looks like {companyName} is subject to the{" "}
            <ExternalLink type="AU" /> and may be subject to the forthcoming{" "}
            <ExternalLink type="NSW" />. {companyName} is legally required to
            publish a modern slavery statement.
          </Typography>
          </Grid>
        </Grid>
      <Grid container >
        <Grid item className={classes.additionalInfo}>
          Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
              <ExpandMoreIcon />
              </IconButton>
            </CardActions>
        </Grid>
        <Grid item>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
        <Typography className={classes.list}>
        To comply with the <ExternalLink type="AU" />, your company’s
        statement must be approved by the principal governing body of your
        company and include:
        </Typography>
        <ul className={classes.list}>
          <li className={classes.listItem}>
          the identity of your company
          </li>
          <li className={classes.listItem}>
          a description of the structure, operations and supply chains of
          your company
          </li>
          <li className={classes.listItem}>
          a description of the risks of modern slavery practices in the
          operations and supply chains of your company, and any entities
          that your company owns or controls
          </li>
          <li className={classes.listItem}>
          a description of the actions taken by your company and any
          entity that it owns or controls, to assess and address those
          risks, including due diligence and remediation processes
          </li>
          <li className={classes.listItem}>
          a description of how your company assesses the effectiveness of
          the actions above
          </li>
          <li className={classes.listItem}>
            a description of the process of consultation with any entities
            that your company owns or controls
          </li>
          <li className={classes.listItem}>
            any other information that your company considers relevant
          </li>
        </ul>
        <Typography className={classes.list}>
          The statement must be updated and submitted annually to the Australian Federal Government.
        </Typography>
      </CardContent>
    </Collapse>
    </Grid>
  </Grid>
        </div>
      </React.Fragment>
    );
  }

  // should never happen
  return null;
}
