import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import blue from "@material-ui/core/colors/blue";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/styles";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import React from "react";

const useStyles = makeStyles(theme => ({
  infoButton: {
    marginLeft: theme.spacing(1)
  },
  infoIcon: {
    color: blue[600]
  },
  popper: {
    maxWidth: 480,
    padding: theme.spacing(2)
  }
}));

function CustomPopper({ children }) {
  const classes = useStyles();

  return (
    <PopupState variant="popper" popupId="gb">
      {popupState => (
        <div>
          <IconButton
            className={classes.infoButton}
            {...bindToggle(popupState)}
          >
            <InfoIcon className={classes.infoIcon} />
          </IconButton>
          <Popper {...bindPopper(popupState)} transition>
            <ClickAwayListener onClickAway={popupState.close}>
              <Paper className={classes.popper} elevation={8}>
                {children}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

export default CustomPopper;
