import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

import config from "../data/config";
import { withFirebase } from "../lib/firebase";
import { GlobalStateContext } from "../lib/globalState";
import { generateEmptyState } from "../lib/utils";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1, 0.75)
  }
}));

const emptyState = generateEmptyState(config);

function SignOutButton({ firebase }) {
  const classes = useStyles();
  const [, dispatch] = useContext(GlobalStateContext);

  function handleClick() {
    // remove any saved data from sessionStorage
    sessionStorage.removeItem("persistedState");
    // reset the state of the app
    dispatch({ type: "RESET", payload: emptyState });
    firebase.doSignOut();
  }

  return (
    <Button
      className={classes.button}
      underline="none"
      color="primary"
      type="button"
      onClick={handleClick}
    >
      Sign Out
    </Button>
  );
}

export default withFirebase(SignOutButton);
