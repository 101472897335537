import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CustomPopper from "./CustomPopper";
import ExternalLink from "./ExternalLink";
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex"
  },
  title: {
    font: 'Bold 14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left',
  },
  text: {
    marginLeft: theme.spacing(10),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(0),
    textAlign: 'justify',
  },
  list: {
    marginLeft: theme.spacing(7),
    paddingBottom: theme.spacing(0),
    font: '14px/22px Roboto',
    color: '#000000',
    textAlign: 'left',
      },
  listCard: {
    marginLeft: theme.spacing(10),
    paddingBottom: theme.spacing(0),
    font: '14px/22px Roboto',
    color: '#000000',
    textAlign: 'left',
      },
  listItem: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2),
    textAlign: 'left'
  },
  cardContent: {
    paddingTop: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: theme.spacing(12),
  },
  additionalInfo: {
    marginLeft: theme.spacing(10),
    textAlign: "left",
    font: "16px/57px Roboto",
    letterSpacing: 0,
    color: "#DF452D",
    maxWidth: "300px",
    paddingRight: "16px",
    display: "flex",
    marginRight: theme.spacing(8),
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    marginLeft: theme.spacing(0),//marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function ReportingObligationsDefault({ companyName, scenario }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };

  if (scenario === 1) return null;

  return (
    <React.Fragment>
      <Typography className={classes.text} variant="body1">
        From the data provided, {companyName} is most likely not subject to:
      </Typography>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          the <ExternalLink type="GB" />
        </li>
        <li className={classes.listItem}>
          the <ExternalLink type="AU" />
        </li>
        <li className={classes.listItem}>
          the <ExternalLink type="US" />
        </li>
        <li className={classes.listItem}>
          the <ExternalLink type="CA" />
        </li>
        <li className={classes.listItem}>
          the <ExternalLink type="FR" />
        </li>
      </ul>
      <div >
        <Typography className={classes.text} variant="body1">
          Every company has a responsibility to undertake human rights due
          diligence. {companyName} is encouraged to comply with international
          best practice by implementing due diligence measures to mitigate
          modern slavery risks in its supply chain.
        </Typography>
        <Grid container >
          <Grid item className={classes.additionalInfo}>
            Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            </Grid>
            <Grid item>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.cardContent}>
                <Typography className={classes.listCard}>
                  In 2011 the UN adopted the UN Guiding Principles on Business and
                  Human Rights (UNGPs) recognizing that every company is responsible
                  and accountable for mitigating adverse human rights in its entire
                  supply chain. The UNGPs define human rights due diligence as a
                  continuous process conducted by business including:
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                  identification of actual and potential negative human rights
                  impacts
                  </li>
                  <li className={classes.listItem}>
                  integration of the findings and implementation of an adequate
                  response mechanism
                  </li>
                  <li className={classes.listItem}>
                    monitoring the effectiveness of the adopted measures and
                  </li>
                  <li className={classes.listItem}>
                  public disclosure of the procedure
                  </li>
                </ul>
              </CardContent>
            </Collapse>
            </Grid>
          </Grid>
      </div>
    </React.Fragment>
  );
}
