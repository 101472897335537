import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { optionsDictionary } from "../data/options";
import factoryIcon from "../img/factory.png";
import IndustryIcon from "./IndustryIcon";
import IndustryPopper from "./IndustryPopper";
import Grid from '@material-ui/core/Grid';
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles(theme => ({
  innerGrid: {
    display: "grid",
    //gridColumnGap: theme.spacing(4),
    //gridTemplateColumns: "min-content 1fr",
    borderTop: '2px solid',
    borderTopColor: grey[100]
  },
  icons: {
    display: "flex",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    //marginLeft: theme.spacing(3)
  },
  list: {
    paddingBottom: theme.spacing(0),
  },
  listItemInner: {
    display: "flex",
    marginLeft: theme.spacing(12),
  },
  listItemManufacturing: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(10),
  },
  card: {
    display: 'flex',

  },
  industryTitle: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(12),
    marginBottom: theme.spacing(2),
    fontWeight: "bold"
  },
  industryContainer: {
    paddingTop: theme.spacing(3),
    borderTop: '1px solid #CCCCCC',
  },

}));

export default function IndustryRisk({ companyName, riskyIndustries }) {
  const classes = useStyles();

  if (riskyIndustries[0] === "option_none") {
    return (
      <Grid container className={classes.industryContainer}>
        <Grid item >
          <Typography className={classes.title} >
            You have indicated that {companyName} does not operate in a
            high-risk industry. Please be aware of the following modern
            slavery risk indicators:
          </Typography>
        </Grid>
        <Grid container >
          <Grid className={classes.listItemInner}>
          <Grid item xs={8} >
            <ul className={classes.list}>
              <li className={classes.listItem}>
                Highly competitive industries (e.g. often resulting in low wages,
                long working hours, unpaid overtime)
              </li>
              <li className={classes.listItem}>
                Industries with low barriers to entry and a high proportion of a
                low skilled workforce
              </li>
              <li className={classes.listItem}>
                High proportion of vulnerable workers (e.g. women migrants or
                refugees)
              </li>
              <li className={classes.listItem}>
                Operations in countries with weak labour laws and none/ineffective
                labour union representation
              </li>
              <li className={classes.listItem}>
                Operation in fragile or conflict affected areas
              </li>
              <li className={classes.listItem}>
                Labour intensive or so called “THREE D” jobs (dirty, dangerous,
                and difficult)
              </li>
            </ul>
          </Grid>
          <Grid item xs={4}>
            <div>
              <img src={factoryIcon} alt="General Industry Icon" width="166" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    );
  }

  return (
<Grid container className={classes.industryContainer}>
 <Grid item >
 <Typography className={classes.title} >
   You have indicated that your company is operating in{" "}
   {riskyIndustries.length} high-risk {riskyIndustries.length === 1 ? 'industry' : 'industries'}:
 </Typography>
  </Grid>
<Grid container >
        {riskyIndustries.map(industry => (
          <Grid item xs={12} key={industry}>
            <div className={classes.industryTitle}>
              {optionsDictionary[industry]}
            </div>
            <Grid className={classes.listItemInner}>
            <Grid item xs={8} >
              <IndustryPopper industry={industry} />
            </Grid>
            <Grid item xs={4}>
              <IndustryIcon industry={industry} key={industry} />
            </Grid>
            </Grid>
          </Grid>
        ))}
</Grid>
</Grid>
  );
}
