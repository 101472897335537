import React from "react";
import CustomInput from "./CustomInput";
import CustomRadio from "./CustomRadio";
import CustomSelect from "./CustomSelect";

export default function FormElement({
  question,
  errors,
  touched,
  values,
  onChange
}) {
  switch (question.type) {
    case "text":
    case "number": {
      const { name, label, type, helperText } = question;
      return (
        <CustomInput
          name={name}
          label={label}
          type={type}
          helperText={helperText}
          values={values}
          errors={errors}
          touched={touched}
          onChange={onChange}
        />
      );
    }
    case "radio": {
      const { name, label, helperText } = question;
      return (
        <CustomRadio
          name={name}
          label={label}
          helperText={helperText}
          values={values}
          errors={errors}
          touched={touched}
          onChange={onChange}
        />
      );
    }
    case "select": {
      const { name, label, helperText, options, multiple } = question;
      return (
        <CustomSelect
          name={name}
          label={label}
          helperText={helperText}
          options={options}
          multiple={multiple}
          values={values}
          errors={errors}
          touched={touched}
          onChange={onChange}
        />
      );
    }
    default: {
      return null;
    }
  }
}
