import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { GlobalStateContext } from "../lib/globalState";
import { generateSchema } from "../lib/utils";
import FormElement from "./FormElement";

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(0, 0.75)
  }
}));

function FormSectionInner({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  isSubmitting,
  handleBack,
  activeStep,
  activeConfig
}) {
  const { title, questions } = activeConfig;
  const classes = useStyles();
  const { S0019_speakUpSystem: speakUpSystem } = values;

  function customHandleChange(event) {
    const { value, name } = event.target;

    // if answer array contains "None of these apply", prevent selection
    // of any other values
    if (
      typeof value === "object" &&
      value.includes("option_none") &&
      value.length > 1
    ) {
      return setFieldValue(name, ["option_none"]);
    }

    // special code to handle the speak up system qualities fields
    if (name === "S0019_speakUpSystem") {
      if (value === "No") {
        // if answer to Q19 is "No", set Q20 to "N/A"
        setFieldValue("S0020_speakUpQualities", ["N/A"]);
      } else if (value === "Yes") {
        // if answer to Q20 is "Yes", reset Q20 to empty state
        setFieldValue("S0020_speakUpQualities", []);
      }
    }

    handleChange(event);
  }

  return (
    <Form autoComplete="off" noValidate>
      <Paper className={classes.section}>
        <Typography component="h2" variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography component="p" variant="body2">
          Please note: all questions are mandatory
        </Typography>
        {questions &&
          questions.map(question => {
            // don't show Q20 unless the answer to Q19 is "Yes"
            if (question.id === 20 && speakUpSystem !== "Yes") {
              return null;
            }

            return (
              <FormElement
                key={question.id}
                question={question}
                values={values}
                errors={errors}
                touched={touched}
                onChange={customHandleChange}
              />
            );
          })}
      </Paper>
      <Button
        className={classes.button}
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        Back
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
      >
        Next
      </Button>
    </Form>
  );
}

function FormSection({ activeConfig, handleBack, handleNext, activeStep }) {
  const [globalState, dispatch] = useContext(GlobalStateContext);
  const schema = generateSchema(activeConfig);
  const validationSchema = Yup.object().shape(schema);
  const activeSection = activeStep + 1;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={globalState.values}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        // save the form data to globalState
        dispatch({
          type: "SAVE_VALUES",
          payload: { values, formStep: activeSection + 1 }
        });
        handleNext();
      }}
    >
      {props => (
        <FormSectionInner
          activeConfig={activeConfig}
          activeStep={activeStep}
          handleBack={handleBack}
          {...props}
        />
      )}
    </Formik>
  );
}

export default FormSection;
