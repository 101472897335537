import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ErrorMessage } from "formik";
import React from "react";

function CustomSelect({
  name,
  label,
  helperText,
  options,
  multiple,
  values,
  errors,
  touched,
  onChange
}) {
  return (
    <FormControl
      margin="normal"
      fullWidth={true}
      required={true}
      error={errors[name] && touched[name] ? true : false}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        id={name}
        name={name}
        value={values[name]}
        multiple={multiple ? true : false}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage name={name}>
        {errorMessage => <FormHelperText>{errorMessage}</FormHelperText>}
      </ErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default CustomSelect;
