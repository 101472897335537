import * as Yup from "yup";

// generate schema according to the config file
export function generateSchema(config) {
  let schema = {};
  config.questions.map(question => {
    const { name, validationType, validations } = question;
    let validator = Yup[validationType]();
    // TODO: check this
    validations &&
      validations.forEach(validation => {
        const { params, type } = validation;
        validator = validator[type](...params);
      });
    return (schema[name] = validator);
  });
  return schema;
}

// generate initial values according to the config file
function generateInitialValues(config) {
  let obj = {};
  const questions = config.sections.map(section => section.questions).flat();
  questions.forEach(question => {
    const { name, initialValue } = question;
    return (obj[name] = initialValue);
  });
  return obj;
}

// generate the empty global state
export function generateEmptyState(config) {
  const initialValues = generateInitialValues(config);

  return {
    formStep: 1,
    isDashboardGenerated: false,
    values: initialValues
  };
}
