import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";
import FormSection from "../components/FormSection";
import ReviewData from "../components/ReviewData";
import Wizard from "../components/Wizard";
import config from "../data/config";
import { GlobalStateContext } from "../lib/globalState";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";
import { withAuthorization, withEmailVerification } from "../lib/session";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(8)
  },
  title: {
    paddingTop: theme.spacing(10),
    padding: theme.spacing(4, 0),
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    marginLeft: "auto",
    marginRight: "auto",
  },
  content: {
    padding: theme.spacing(3)
  }
}));

function FormPage({ history, match }) {
  const classes = useStyles();
  const steps = config.sections.map(section => section.title);
  const [globalState] = useContext(GlobalStateContext);
  const { formStep } = globalState;

  const { id } = match.params;
  const activeId = parseInt(id);
  // activeStep is 1 less because it is 0 indexed
  const activeStep = activeId - 1;
  const activeConfig = config.sections[activeStep];

  function handleNext() {
    const nextStep = activeId + 1;
    history.push(`/form/${nextStep}`);
    // scroll to top
    window.scrollTo(0, 0);
  }

  function handleBack() {
    const previousStep = activeId - 1;
    history.push(`/form/${previousStep}`);
  }

  // prevent form skipping
  if (activeId > formStep) {
    return <Redirect to={`/form/${formStep}`} />;
  }

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          className={classes.title}
        >
          Modern Slavery Risk Assessment Form
        </Typography>
        <Wizard steps={steps} activeStep={activeStep} />
        {activeStep >= steps.length ? (
          <React.Fragment>
            <ReviewData config={config} handleBack={handleBack} />
          </React.Fragment>
        ) : (
          <FormSection
            activeConfig={activeConfig}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        )}
      </Container>
    </main>
  );
}

// prevent non-authorised users from accessing this page
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FormPage);
