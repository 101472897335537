import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ExternalLink from "./ExternalLink";
import icon from '../img/icon-france.svg';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  list: {
    //paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    //textAlign: 'left',
      },
  listItem: {
    font: '14px/22px Roboto',
    marginLeft: theme.spacing(-3),
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left'
  },
  iconImage: {
    opacity: 1,
    marginLeft: theme.spacing(3),
  },
  iconImageDimmed: {
    opacity: 0.2,
    marginLeft: theme.spacing(3),
  },
  title: {
    font: 'Bold 14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left',
  },
  text: {
    marginLeft: 0,
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(0),
    textAlign: 'justify',
    paddingTop: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    marginLeft: theme.spacing(0),//marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  additionalInfo: {
    textAlign: "left",
    font: "16px/57px Roboto",
    letterSpacing: 0,
    color: "#DF452D",
    maxWidth: "300px",
    paddingRight: "16px",
    display: "flex",
    marginRight: theme.spacing(8),
  },
  cardContent: {
    paddingTop: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: theme.spacing(12),
  },
  collapse: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  }
  }));

export default function ReportingObligationsFR({
  companyName,
  scenario,
  isFRRegistered,
  hasFROperations,
  headcount
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };

  if (scenario === 2) return null;

  // outcome 1
  if (!isFRRegistered && !hasFROperations && headcount !== "e") {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
      <div><Typography className={classes.title}>
        France
      </Typography>
      <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="FR" />{" "}
            but is encouraged to implement due diligence measures to mitigate
            modern slavery risks within the company's supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 2
  if (!isFRRegistered && !hasFROperations && headcount === "e") {
    return (
      <React.Fragment>
              <div>
                <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
                  <div>
                    <Typography className={classes.title}>
                      France
                    </Typography>
                    <Typography className={classes.text} variant="body1">
                      It looks like {companyName} is not subject to the{" "}
                      <ExternalLink type="FR" /> {" "}as it does not carry on business in
                      France. It is encouraged however to implement due diligence measures
                      to mitigate modern slavery risks within the company's supply chain.
                  </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 3
  if (!isFRRegistered && hasFROperations && headcount === "d") {
    return (
      <React.Fragment>
        <div>
          <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImageDimmed}/>
        </div>
        <div>
          <Typography className={classes.title}>
            France
          </Typography>
          <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="FR" />. As {companyName} has business operations
            in France it is encouraged to implement due diligence measures to
            mitigate modern slavery risks in its supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 4
  if ((isFRRegistered || hasFROperations) && headcount === "e") {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImage}/></div>
      <div><Typography className={classes.title}>
        France
      </Typography>
      <Grid container >
        <Grid item >
          <Typography className={classes.text} variant="body1">
            {companyName} may be subject to the <ExternalLink type="FR" /> {" "} and
            is therefore required to publish and implement a vigilance plan to
            identify and mitigate adverse human rights impacts.
          </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item className={classes.additionalInfo}>
            Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            </Grid>
            <Grid item>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.cardContent}>
                <Typography className={classes.list}>At a minimum a vigilance plan must include:</Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    a plan to engage in supply chain mapping that identifies,
                    analyses and ranks risks
                  </li>
                  <li className={classes.listItem}>
                    procedures to regularly assess, in accordance with the risk
                    mapping, the relationship between your subsidiaries,
                    subcontractors and suppliers
                  </li>
                  <li className={classes.listItem}>
                    a description of appropriate action your company takes to
                    mitigate risks or prevent serious violations
                  </li>
                  <li className={classes.listItem}>
                    An alert mechanism that collects reports of existing or actual
                    risks, developed in partnership with the trade union
                    representatives of the company concerned.
                  </li>
                  <li className={classes.listItem}>
                    a monitoring scheme to follow up on the measures implemented and
                    to assess their efficiency
                  </li>
                </ul>
              </CardContent>
            </Collapse>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  // outcome 5
  if (
    (isFRRegistered || hasFROperations) &&
    headcount !== "d" &&
    headcount !== "e"
  ) {
    return (
      <React.Fragment>
      <div>
        <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImageDimmed}/>
      </div>
      <div>
        <Typography className={classes.title}>
          France
        </Typography>
        <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="FR" /> {" "} but is encouraged to implement due
            diligence measures to mitigate modern slavery risks within the company's supply
            chain.
        </Typography>
      </div>
    </React.Fragment>
    );
  }

  // outcome 6
  if (isFRRegistered && headcount === "d") {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="French jurisdiction" width="44" className={classes.iconImage}/></div>
      <div><Typography className={classes.title}>
        France
      </Typography>
      <Grid container >
        <Grid item >
          <Typography className={classes.text} variant="body1">
            {companyName} may be subject to the <ExternalLink type="FR" /> {" "} and
            is therefore required to publish and implement a vigilance plan to
            identify and mitigate adverse human rights impacts.
          </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item className={classes.additionalInfo}>
            Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            </Grid>
            <Grid item>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.cardContent}>
                <Typography className={classes.list}>At a minimum a vigilance plan must include:</Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    a plan to engage in supply chain mapping that identifies,
                    analyses and ranks risks
                  </li>
                  <li className={classes.listItem}>
                    procedures to regularly assess, in accordance with the risk
                    mapping, the relationship between your subsidiaries,
                    subcontractors and suppliers
                  </li>
                  <li className={classes.listItem}>
                    a description of appropriate action your company takes to
                    mitigate risks or prevent serious violations
                  </li>
                  <li className={classes.listItem}>
                    an alert mechanism that collects reports of existing or actual
                    risks, developed in partnership with the trade union
                    representatives of the company concerned
                  </li>
                  <li className={classes.listItem}>
                    a monitoring scheme to follow up on the measures implemented and
                    to assess their efficiency
                  </li>
                </ul>
              </CardContent>
            </Collapse>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  // should never happen
  return null;
}
