import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { optionsDictionary } from "../data/options";
import { AuthUserContext } from "../lib/session";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import grey from "@material-ui/core/colors/grey";
import ComplySummary from "../components/ComplySummary";
import iconFileboard from '../img/icon-fileboard.svg';
import iconGlobe from '../img/icon-globe.svg';
import iconSuitcase from '../img/icon-suitcase.svg';
import iconTruck from '../img/icon-truck.svg';
import iconStats from '../img/icon-stats.svg';
import iconScript from '../img/icon-script.svg';


const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3)
  },
  title: {
    //marginTop: theme.spacing(1),
    //fontSize: '200%',
    //fontWeight: 'normal',
    marginBottom: theme.spacing(2),
    //marginRight: theme.spacing(6),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left',
    color: '#000000',
    opacity: 1
  },
  textHeading: {
    fontWeight: 'bold',
    color: grey[500],
    fontSize: '100%',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '150%',
  },
  introWrapper: {
    fontStyle: "normal",
    marginBottom: theme.spacing(5)
  },
  introText: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "300px",
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3)
  },
  underlines: {
    //color: grey[500],
    borderBottom: '2px solid',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottomColor: grey[100]
  },
  inner: {
     margin: "auto"
   }
}));

function changeFocus(id) {
  var element = document.getElementById(id);
  if (element !== null) {
    element.scrollIntoView({behavior: "auto"});
    }
}

export default function DashboardHeader({ data, risk }) {
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);
  const {
    S0001_companyName: companyName,
    S0002_registrationLocation: registrationLocation,
    S0003_globalTurnover: globalTurnover,
    S0004_globalHeadcount: globalHeadcount
  } = data;

  return (
    <Paper className={classes.wrapper}>
      <Grid container >
        <Grid item xs={6}>
            <Box display='flex' className={classes.underlines}>
              <Typography className={classes.title} component="h1" >
                Your modern slavery risk profile {/*for {companyName}*/}
              </Typography>
            </Box>
            <Box display='flex' className={classes.underlines}>
              <div className={classes.grid}>
                <Typography className={classes.textHeading}>Name of your organisation:</Typography>
                <Typography className={classes.text}>
                  {companyName}
                </Typography>
              </div>
            </Box>
            <Box display='flex' className={classes.underlines}>
              <div className={classes.grid}>
                <Typography className={classes.textHeading}>Country of registration:</Typography>
                <Typography className={classes.text}>
                  {optionsDictionary[registrationLocation]}
                  </Typography>
              </div>
            </Box>
            <Box display='flex' className={classes.underlines}>
              <div className={classes.grid}>
                <Typography className={classes.textHeading}>
                  Global turnover in last year ($m):
                </Typography>
                <Typography className={classes.text}>
                  {optionsDictionary[globalTurnover]}
                </Typography>
              </div>
            </Box>
            <Box display='flex'>
              <div className={classes.grid}>
                <Typography className={classes.textHeading}>Worldwide employment headcount:</Typography>
                <Typography className={classes.text}>
                  {optionsDictionary[globalHeadcount]}
                </Typography>
              </div>
            </Box>
          </Grid>
        <Grid item xs={6} >
          <Grid container spacing={3} className={classes.container}>
            <Grid item >
              <ButtonBase
                centerRipple
                onClick={() => changeFocus('sectionReportingObligations')}
              >
                <ComplySummary risk={risk[0]} image={iconFileboard} text={"Reporting Obligations"}/>
              </ButtonBase>
            </Grid>
            <Grid item >
            <ButtonBase
              centerRipple
              onClick={() => changeFocus('sectionCountry')}
            >
              <ComplySummary risk={risk[1]} image={iconGlobe} text={"Country"}/>
            </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase
                centerRipple
                onClick={() => changeFocus('sectionIndustry')}
              >
                <ComplySummary risk={risk[2]} image={iconSuitcase} text={"Industry"}/>
              </ButtonBase>
            </Grid>
            <Grid item >
              <ButtonBase
                centerRipple
                onClick={() => changeFocus('sectionProduct')}
              >
                <ComplySummary risk={risk[3]} image={iconTruck} text={"Product"}/>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase
                centerRipple
                onClick={() => changeFocus('sectionEmployment')}
              >
                <ComplySummary risk={risk[4]} image={iconStats} text={"Employment Mode"}/>
              </ButtonBase>
            </Grid>
            <Grid item >
              <ButtonBase
                centerRipple
                onClick={() => changeFocus('sectionPoliciesPractices')}
              >
                <ComplySummary risk={risk[5]} image={iconScript} text={"Policies & Processes"}/>
              </ButtonBase>
            </Grid>
          </Grid>
          </Grid>
      </Grid>
    </Paper>
  );
}
