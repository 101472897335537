import Container from "@material-ui/core/Container";
import { Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { compose } from "recompose";
import { GlobalStateContext } from "../lib/globalState";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";
import { withAuthorization, withEmailVerification } from "../lib/session";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(-2),
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(4)
  },

  wrapper: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(0),
    minWidth: "900px",
    padding: theme.spacing(0)
  },
  title: {
    marginLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3)
  },
  intro: {
    fontStyle: "normal",
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    marginRight: theme.spacing(6),
  },
  intro2: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  detailNumber: {
    textAlign: "left",
    font: "24px/57px Roboto",
    letterSpacing: 0,
    color: "#4276DC",
  },
  detailTitle: {
    textAlign: "left",
    font: "24px/57px Roboto",
    letterSpacing: 0,
    color: "#000000"
  },
  detailHeading: {
    textAlign: "left",
    font: "Bold 14px/22px Roboto",
    letterSpacing: 0,
    color: "#0C0C0C"
  },
  detail: {
    textAlign: "left",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#0C0C0C",
  },
  ul: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    textAlign: "left",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#000000",
    opacity: 1,
    listStyle: 'none',
    position: 'relative'
  },
  li: {
    '&::before': {
      content: '"•"',
      color: "#4276DC",
      display:'inline-block',
      fontSize: '40px',
      //verticalAlign: 'middle',
      width: '1em',
      position: 'absolute',
      marginLeft: theme.spacing(-5),
      transform: "translateY(2px)"
      //transform: "translateY(10px) rotate(45deg)"
      //transformOrigin: '80%'
      //marginLeft: '-1em'
    },
    marginTop: theme.spacing(1),
    //textAlign: 'justify'
  },
  listItem: {
    marginTop: theme.spacing(1),
  },


  blueBox: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #4276DC",
    borderRadius: "5px",
    opacity: 1,
    width: "303px",
    height: "120px",
    textAlign: "left",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#0C0C0C",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2)
  },
  blueBoxInner: {
    position: "relative",
    marginTop: theme.spacing(1),
    width: "275px",
    left: "5%",
    top: "5%",
    textAlign: 'justify'
  },
  link: {
    display: "inline-block",
    marginTop: theme.spacing(3),
    textDecoration: "none"
  },
  expanded:  {
  '&$expanded': {
          margin: '0px 0'
       }
    },
  footerText: {
    margin: theme.spacing(3),
    textAlign: "justify",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#0C0C0C",
  }
}));

function DueDiligencePage() {
  const classes = useStyles();
  const [globalState] = useContext(GlobalStateContext);
  const { values } = globalState;

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="lg" spacing={0}>
        {values ? (
          <React.Fragment>
            <Paper className={classes.wrapper}>
            <Grid container spacing={0} >
            <Grid item xs={9}>
              <Typography
                className={classes.title}
                component="h1"
                variant="h4"
                color="textPrimary"
              >
                Your modern slavery due diligence process
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <a
                className={classes.link}
                href="https://www.rightsdd.com/rightsddnow-contact"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button variant="contained" color="primary" type="button">
                  Contact RightsDD
                </Button>
              </a>
              </Grid>
              </Grid>
              <Typography className={classes.intro} variant="body2">
                The UN Guiding Principles on Business and Human Rights confirm that
                corporations have a responsibility to respect human rights and are accountable
                for negative human rights impacts, including modern slavery, resulting
                from their business activities. In order to tackle slavery in supply
                chains, corporations are required to conduct modern slavery due diligence.
                The process is designed to prevent, mitigate and remediate modern slavery.
              </Typography>
              <Typography className={classes.intro} variant="body2">
                This section provides an overview of where your company stands
                in the due diligence process and provides guidance on the next
                steps in your company’s journey to fully implement due diligence.
              </Typography>
              <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1} defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <div className={classes.detailNumber}>01 &nbsp;</div>
                <div className={classes.detailTitle}>Get Started</div>
                </ExpansionPanelSummary>
              <ExpansionPanelDetails >
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Grid item>
                      <Typography className={classes.detailHeading}>
                        Stage 1: You are getting started
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.detail}>
                        By completing RightsDDnow you have started to familiarize
                        yourself with the process of modern slavery due diligence and
                        have learned about potential high-risk areas in your
                        business operations.
                      </Typography>
                      <p />
                      <Typography className={classes.detail}>
                      It is now necessary to ensure that respect for human rights
                      becomes deeply integrated into your corporation’s business activities. You should:
                      </Typography>
                      <p />

                      <ul className={classes.ul}>
                        <li className={classes.li}>raise awareness on modern slavery and the potential
                              negative impacts of your business operations on worker rights</li>
                        <li className={classes.li}>involve senior management</li>
                        <li className={classes.li}>clearly assign responsibilities</li>
                        <li className={classes.li}>adopt core policies & practices</li>
                        <li className={classes.li}>publish a modern slavery statement</li>
                      </ul>

                    </Grid>
                  </Grid>
                  <Grid item cs={5}>
                    <div className={classes.blueBox}>
                    <div className={classes.blueBoxInner}><strong>What's next?</strong><br />
                    Click through the six stages below to learn more about your
                    next steps in the due diligence process.
                    </div></div>
                  </Grid>
                </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                          <div className={classes.detailNumber}>02 &nbsp;</div>
                          <div className={classes.detailTitle}>Identify Risks</div>
                          </ExpansionPanelSummary>
                        <ExpansionPanelDetails >
                        <Grid container spacing={0}>
                          <Grid item>
                            <Typography className={classes.detailHeading}>
                              Stage 2: Identify risks in your supply chain
                            </Typography>
                            <Typography className={classes.detail}>
                              Modern Slavery can take place in every country and every industry.
                              It is essential that you start by identifying the weaknesses
                              in your own business operations and supply chain which cause
                              or contribute to modern slavery. You should:
                          </Typography>
                            <ul className={classes.ul}>
                              <li className={classes.li}>assess your own operations; identify main areas for potential modern slavery risks and identify affected groups</li>
                              <li className={classes.li}>assess your Tier 1 suppliers</li>
                              <li className={classes.li}>assess your business operations and new suppliers on an ongoing basis</li>
                              <li className={classes.li}>assess your Tier 2+ suppliers</li>
                            </ul>
                          </Grid>
                        </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                        <div className={classes.detailNumber}>03 &nbsp;</div>
                        <div className={classes.detailTitle}>Integrate Findings</div>
                        </ExpansionPanelSummary>
                      <ExpansionPanelDetails >
                      <Grid container spacing={0}>
                        <Grid item>
                          <Typography className={classes.detailHeading}>
                            Stage 3: Integrate your findings
                          </Typography>
                          <Typography className={classes.detail}>
                            Use your findings as the basis for remediation planning and taking
                            appropriate action. You should:
                          </Typography>
                          <ul className={classes.ul}>
                            <li className={classes.li}>identify gaps in existing policies & practices</li>
                            <li className={classes.li}>prioritize action and develop risk treatment plans</li>
                            <li className={classes.li}>adapt existing policies & practices in accordance with risk treatment plans</li>
                            <li className={classes.li}>implement new measures</li>
                          </ul>
                        </Grid>
                      </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                      <div className={classes.detailNumber}>04 &nbsp;</div>
                      <div className={classes.detailTitle}>Track Performance</div>
                      </ExpansionPanelSummary>
                    <ExpansionPanelDetails >
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography className={classes.detailHeading}>
                          Stage 4: Track your performance
                        </Typography>
                        <Typography className={classes.detail}>
                          Modern slavery due diligence is not a one-off exercise but a continuous
                          process which aims to drive constant improvement on how your company
                          manages slavery risks. You should:
                        </Typography>
                        <ul className={classes.ul}>
                          <li className={classes.li}>use appropriate qualitative and quantitative indicators to assess the effectiveness of the implemented risk treatments</li>
                          <li className={classes.li}>draw on feedback from internal and external stakeholders</li>
                          <li className={classes.li}>include feedback from affected workers</li>
                          <li className={classes.li}>assess data collected from internal grievance mechanisms</li>
                        </ul>
                      </Grid>
                    </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                    <div className={classes.detailNumber}>05 &nbsp;</div>
                    <div className={classes.detailTitle}>Remediate</div>
                    </ExpansionPanelSummary>
                  <ExpansionPanelDetails >
                  <Grid container spacing={0}>
                    <Grid item>
                      <Typography className={classes.detailHeading}>
                        Stage 5: Remediate
                      </Typography>
                      <Typography className={classes.detail}>
                        Taking early and effective remediation is key for both minimising modern
                        slavery risks arising from your business operations and for preventing
                        escalation of other human rights risk factors. You should:
                      </Typography>
                      <ul className={classes.ul}>
                        <li className={classes.li}>integrate grievance mechanisms to enable workers to raise concerns</li>
                        <li className={classes.li}>ensure that grievance mechanisms are:</li>
                          <ul>
                            <li >free of charge</li>
                            <li >accessible to every employee</li>
                            <li >provided in a language(s) understood by every employee</li>
                            <li >can be used anonymously</li>
                          </ul>
                        <li className={classes.li}>consider cooperation with external actors</li>
                      </ul>
                    </Grid>
                  </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={1}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                  <div className={classes.detailNumber}>06 &nbsp;</div>
                  <div className={classes.detailTitle}>Communicate</div>
                  </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography className={classes.detailHeading}>
                      Stage 6: Communicate your due diligence process
                    </Typography>
                    <Typography className={classes.detail}>
                      Your modern slavery due diligence processes increase overall supply chain
                      transparency and promote positive corporate social responsibility.
                      You should be prepared to:
                    </Typography>
                    <ul className={classes.ul}>
                      <li className={classes.li}>report formerly on how slavery is assessed e.g. in form of a Modern
                        Slavery Statement (update frequently and ensure accessibility)</li>
                      <li className={classes.li}>communicate relevant policies and requirements to suppliers</li>
                      <li className={classes.li}>communicate with workers in your own operations and supply chain about
                        the due diligence and their rights</li>
                    </ul>
                  </Grid>
                </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Paper>
            <Grid container>
              <Grid item>
                <Typography className={classes.footerText}>
                RightsDD empowers companies to tackle modern slavery in their operations
                and supply chains. Click <Link color="primary" href="mailto:support@rightsdd.com?subject=Request for further information">here</Link> for support or to learn more about our products and services.
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Paper className={classes.content}>
            <Typography component="p" variant="body1" align="center">
              No data found. Please go back and submit the form again.
            </Typography>
          </Paper>
        )}
      </Container>
    </main>
  );
}

// prevent non-authorised users from accessing this page
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(DueDiligencePage);
