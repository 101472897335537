import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ExternalLink from "./ExternalLink";
import icon from '../img/icon-uk.svg';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles(theme => ({
  list: {
    //paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
  },
  listItem: {
    font: '14px/22px Roboto',
    marginLeft: theme.spacing(-3),
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left'
  },
  iconImage: {
    opacity: 1,
    marginLeft: theme.spacing(3),
  },
  iconImageDimmed: {
    opacity: 0.2,
    marginLeft: theme.spacing(3),
  },
  title: {
    font: 'Bold 14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left',
  },
  text: {
    marginLeft: 0,
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(0),
    textAlign: 'justify',
    paddingTop: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    marginLeft: theme.spacing(0),//marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  additionalInfo: {
    textAlign: "left",
    font: "16px/57px Roboto",
    letterSpacing: 0,
    color: "#DF452D",
    maxWidth: "300px",
    paddingRight: "16px",
    display: "flex",
    marginRight: theme.spacing(8),
  },
  cardContent: {
    paddingTop: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: theme.spacing(12),
  },
  collapse: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  }));

export default function ReportingObligationsGB({
  companyName,
  scenario,
  isGBRegistered,
  hasGBOperations,
  isOverGBTurnoverThreshold
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };

  if (scenario === 2) return null;

  // outcome 1
  if (!isGBRegistered && !hasGBOperations && !isOverGBTurnoverThreshold) {
    return (
      <React.Fragment>
      <div >
      <img src={icon} alt="UK jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
      <div><Typography className={classes.title} >
        United Kingdom
      </Typography>
      <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not obliged to publish a modern
            slavery statement under Section 54 of the <ExternalLink type="GB" />
            {" "} but is encouraged to implement due diligence measures to mitigate
            modern slavery risks within the company's supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 2
  if (!isGBRegistered && !hasGBOperations && isOverGBTurnoverThreshold) {
    return (
      <React.Fragment>
              <div>
                <img src={icon} alt="UK jurisdiction" width="44" className={classes.iconImageDimmed}/></div>
                  <div>
                    <Typography className={classes.title}>
                      United Kingdom
                    </Typography>
                    <Typography className={classes.text} variant="body1">
                      It looks like {companyName} is not obliged to publish a modern
                      slavery statement under Section 54 of the <ExternalLink type="GB" />
                      {" "} as it does not carry on business in the UK. It is encouraged however
                      to implement due diligence measures to mitigate modern slavery risks
                      within the company's supply chain.
                    </Typography>
                  </div>
      </React.Fragment>
    );
  }

  // outcome 3
  if ((isGBRegistered || hasGBOperations) && !isOverGBTurnoverThreshold) {
    return (
      <React.Fragment>
        <div>
          <img src={icon} alt="UK jurisdiction" width="44" className={classes.iconImageDimmed}/>
        </div>
        <div>
          <Typography className={classes.title}>
            United Kingdom
          </Typography>
          <Typography className={classes.text} variant="body1">
            It looks like {companyName} revenue falls below the revenue
            threshold and is not therefore obliged to publish a modern slavery
            statement under Section 54 of the <ExternalLink type="GB" />. It is
            encouraged however to implement due diligence measures to mitigate
            modern slavery risks within the company's supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 4
  if ((isGBRegistered || hasGBOperations) && isOverGBTurnoverThreshold) {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="UK jurisdiction" width="44" className={classes.iconImage}/></div>
      <div><Typography className={classes.title}>
        United Kingdom
      </Typography>
      <Grid container >
        <Grid item >
          <Typography className={classes.text} variant="body1">
            It looks like {companyName} is legally required to publish a modern
            slavery statement every year under Section 54 of the{" "}
            <ExternalLink type="GB" />. The modern slavery statement should
            outline the steps taken to prevent modern slavery within the company's supply
            chain.
          </Typography>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item className={classes.additionalInfo}>
          Additional Information
          <CardActions >
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          </Grid>
          <Grid item>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent className={classes.cardContent}>
              <Typography className={classes.list}>At a minimum your company is obliged to:</Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  publish a Modern Slavery Statement on your company’s website
                </li>
                <li className={classes.listItem}>
                  ensure the statement is <strong>approved</strong> by your
                  company’s board of directors (or equivalent) and <strong>signed</strong> by a
                  director (including the date of authorization)
                </li>
                <li className={classes.listItem}>
                  provide a clearly visible link to the statement on the homepage
                  of your company’s website
                </li>
                <li className={classes.listItem}>
                  If your company is a limited liability partnership, the statement
                  must be approved by the members and signed by a designated member
                </li>
              </ul>
            </CardContent>
          </Collapse>
          </Grid>
        </Grid>
          </div>
      </React.Fragment>
    );
  }

  // should never happen
  return null;
}
