import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import React, { useContext } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { compose } from "recompose";
import TopBar from "./components/TopBar";
import { GlobalStateContext, withGlobalState } from "./lib/globalState";
import { withAuthentication } from "./lib/session";
import theme from "./lib/theme";
import AccountPage from "./pages/AccountPage";
import DashboardPage from "./pages/DashboardPage";
import DueDiligencePage from "./pages/DueDiligencePage";
import FormPage from "./pages/FormPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import SignupPage from "./pages/SignupPage";
import EducationPage from "./pages/EducationPage";
import NotFound from './components/NotFound.js';


const useStyles = makeStyles(() => ({
  "@global": {
    body: {
      minWidth: "320px",
      top: "320px",
      left: "410px",
      width: "900px",
      height: "433px",
      margin: "0 auto"
    }
  }
}));

function AppBase() {
  const [globalState] = useContext(GlobalStateContext);
  const { isDashboardGenerated } = globalState;

  // additional global styles
  useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <TopBar />
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/form"
          render={() =>
            isDashboardGenerated ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/form/1" />
            )
          }
        />
        <Route
          exact
          path="/form/:id"
          render={props =>
            isDashboardGenerated && false ? (
              <Redirect to="/dashboard" />
            ) : (
              <FormPage {...props} />
            )
          }
        />
        <Route
          exact
          path="/dashboard"
          render={props =>
            isDashboardGenerated ? (
              <div>
              <DashboardPage {...props} /></div>
            ) : (
              <Redirect to="/form/1" />
            )
          }
        />
        <Route exact path="/due-diligence" component={DueDiligencePage} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/password-reset" component={PasswordResetPage} />
        <Route exact path="/create-account" component={CreateAccountPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup"
          component={() => {window.location.href = 'https://www.rightsdd.com/rightsddnow-signup';
          return null;
          }}/>
        {/* enable when general signup period is acticated <Route exact path="/signup" component={SignupPage} />*/}
        <Route exact path="/learn/:id" component={EducationPage} />
        {/*<Route path="*" component={NotFound} />*/}
        {/*TO DO: ADD CATCHALL NO VALID URL*/}
      </Router>
    </ThemeProvider>
  );
}

const App = compose(
  withAuthentication,
  withGlobalState
)(AppBase);

export default App;
