import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "./firebase";
import EmailVerificationPage from "../pages/EmailVerificationPage";

const AuthUserContext = React.createContext(null);

const withAuthentication = Component => {
  function WithAuthentication({ firebase, ...props }) {
    const existingAuthUser = JSON.parse(sessionStorage.getItem("authUser"));
    const [authUser, setAuthUser] = useState(existingAuthUser);

    useEffect(() => {
      // listen for auth state changes
      const listener = firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          setAuthUser(authUser);
        } else {
          sessionStorage.removeItem("authUser");
          setAuthUser(null);
        }
      });
      // unsubscribe to the listener when unmounting
      return () => listener();
    }, [firebase.auth]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return withFirebase(WithAuthentication);
};

const withAuthorization = condition => Component => {
  function WithAuthorization({ firebase, history, ...props }) {
    const authUser = useContext(AuthUserContext);

    useEffect(() => {
      // listen for auth state changes
      const listener = firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          history.push("/login");
        }
      });
      // unsubscribe to the listener when unmounting
      return () => listener();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebase.auth]);

    // don't render the disallowed Component at all
    return condition(authUser) ? (
      <Component history={history} {...props} />
    ) : null;
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization);
};

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes("password");

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }))
        .catch(error => {
          console.log(error);
        });
    };

    render() {
      const { isSent } = this.state;

      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <EmailVerificationPage
                isSent={isSent}
                onSendEmailVerification={this.onSendEmailVerification}
              />
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return withFirebase(WithEmailVerification);
};

export {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
  withEmailVerification
};
