import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";
import IndustryRisk from "./IndustryRisk";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3)
  },
  title: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left'
  },
  intro: {
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    marginRight: theme.spacing(4),
  },
  grid: {
    display: "grid",
    gridRowGap: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  dot: {
    height: '30px',
    width: '30px',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1,
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
    //borderWidth: 3,
    //borderStyle: 'inset'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
}));

function IndustryRiskMain({ data, risk }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const {
    S0001_companyName: companyName,
    S0007_riskyIndustries: riskyIndustries,
    S0009_manufactureProducts: manufactureProducts
  } = data;

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={expanded === false ? <RemoveIcon fontSize="large" fontWeight="bold"/> : <AddIcon fontSize="large" fontWeight="bold"/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleExpandClick}
      >
        <Grid container >
          <Grid container >
            <Grid item xs={1} >
              <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
            </Grid>
            <Grid item xs={11} >
              <Typography className={classes.title} component="h4" variant="h5">
                Your industry risk
              </Typography>
            </Grid>
          </Grid>
        <Grid container >
          <Grid item xs={1}/><Grid item xs={11} >
            <Typography className={classes.intro} variant="body2">
                Modern Slavery takes place in every business sector. However, certain
                industry factors are highly correlated with labour rights abuse; these
                industries have a high prevalence of slavery related products and services.
                If your company is operating in an industry with an inherent high risk of
                slavery you need to pay special attention to managing industry risk.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <div className={classes.grid}>
        <IndustryRisk
          riskyIndustries={riskyIndustries}
          companyName={companyName}
        />
      </div>
    </ExpansionPanel>
  );
}

export default IndustryRiskMain;
