import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import { makeStyles } from "@material-ui/styles";
import { scaleLinear } from "d3-scale";
import React from "react";
import {
  Cell,
  Label,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import countriesJson from "../data/countries.json";

const useStyles = makeStyles(theme => ({
  tooltip: {
    //original color is white
    backgroundColor: "black",
    padding: theme.spacing(1),
    font: "14px/19px Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
    borderRadius: "10px",
  },
  heading: {
    fontWeight: "bold",
    margin: 0
  },
  copy: {
    marginBottom: 4,
    marginTop: 4
  },
  point: {
    fill: "white"
  },
  point_: {
  '&:hover': {
    fillOpacity: 1,
  }
}
}));
class CustomizedDot extends React.Component {
    render() {
        const { cx, cy } = this.props;

        return (
            <circle cx={160} cy={50} r={25} stroke="black" strokeWidth={3} fill="red" />
        );
    }
};

const CustomTooltip = ({ active, payload }) => {
  const classes = useStyles();
  if (active) {
    const { name, government_response, prevalence_score } = payload[0].payload;
    return (
      <div className={classes.tooltip}>
        <p className={classes.heading}>{name}</p>
        <p className={classes.copy}>
          Slaves per 1,000 of population: {prevalence_score.toFixed(2)}
        </p>
        <p className={classes.copy}>
          Strength of governance index: {government_response.toFixed(2)}
        </p>
      </div>
    );
  }

  return null;
};
const markerColourScale = scaleLinear()
  .domain([0, 8.214])
  .range([yellow[300], red[900]]);

export default function RiskMatrix({ countriesActive }) {
  const graphData = countriesJson
    .filter(country =>
      countriesActive.includes(`option_country_${country.country_code}`)
    )
    .map(country => ({
      name: country.country_name,
      x: country.ms_prevalence_score_C,
      y: country.ms_government_response_C,
      government_response: country.ms_government_response,
      prevalence_score: country.ms_prevalence_score
    }));
  const classes = useStyles();
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ScatterChart margin={{ right: 40 }}>
        <ReferenceArea
          x1={0}
          x2={4.107}
          y1={-76.2381}
          y2={-34.8214}
          fill={"#FFFDEB"}
          fillOpacity={1}
          stroke="white"
          strokeWidth={3}
          strokeOpacity={1}
        >
          <Label
            //value="Lowest Risk, Low Prevalence, High Regulation"
            fill={grey[600]}
            position="insideBottomLeft"
          />
        </ReferenceArea>
        <ReferenceArea
          x1={4.107}
          x2={8.214}
          y1={-76.2381}
          y2={-34.8214}
          fill={"#FFF4E5"}
          fillOpacity={1}
          stroke="white"
          strokeWidth={3}
          strokeOpacity={1}
        >
          <Label
            //value="High Prevalence, High Regulation"
            fill={grey[600]}
            position="insideBottomRight"
          />
        </ReferenceArea>
        <ReferenceArea
          x1={0}
          x2={4.107}
          y1={-34.8214}
          y2={6.5952}
          fill={"#FFF4E5"}
          fillOpacity={1}
          stroke="white"
          strokeWidth={3}
          strokeOpacity={1}
        >
          <Label
            //value="Low Prevalance, Low Regulation"
            fill={grey[600]}
            position="insideTopLeft"
          />
        </ReferenceArea>
        <ReferenceArea
          x1={4.107}
          x2={8.214}
          y1={-34.8214}
          y2={6.5952}
          fill={"#feeceb"}
          fillOpacity={1}
          stroke="white"
          strokeWidth={3}
          strokeOpacity={1}
        >
          <Label
            //value="Highest Risk, High Prevalance, Low Regulation"
            fill={grey[600]}
            position="insideTopRight"
          />
        </ReferenceArea>
        <XAxis
          hide={true}
          type="number"
          tick={false}
          dataKey="x"
          domain={[0, 8.214]}
          name="Slavery prevalence index"
          label={{
            value: "Slaves per 1,000 of population"
          }}
        />
        <YAxis
          hide={true}
          type="number"
          tick={false}
          dataKey="y"
          domain={[-76.2381, 6.5952]}
          name="Country strength of governance index"
          label={{
            value: "Country strength of governance index",
            angle: -90
          }}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: "3 3" }}
        />
        <Scatter className={classes.point_} data={graphData}>
          {/*<LabelList dataKey="name" position="bottom" fill="black" />*/}
          {graphData.map((entry, index) => (
            <Cell key={`cell-${index}`} className={classes.point_} fillOpacity={1} strokeWidth={10} size={200} fill={markerColourScale(entry.x)} />
           ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}
