const config = {
  sections: [
    {
      pid: 0,
      pageTitle: "Education Page 1",
      contentHeading: "What is modern slavery?",
      contentText: "Modern Slavery is an umbrella term used to describe some of the most severe human rights abuses including human trafficking, forced labour, the worst forms of child labour and debt bondage. According to the International Labour Organisation (ILO), approximately 16 million slaves are exploited in the private sector to produce the goods and services we consume."
    },
    {
      pid: 1,
      pageTitle: "Education Page 2",
      contentHeading: "How are companies causing or contributing to modern slavery?",
      contentText: "Modern Slavery is a systemic issue often hidden in vast and complex supply chains. The identification of victims can be very difficult. Although Modern Slavery takes place in every country and industry, certain business models, corporate governance structures, employment schemes and production models are more likely to facilitate Modern Slavery. Awareness of  modern slavery risk factors is essential if companies want to eliminate this risk effectively from their business operations."
    },
    {
      pid: 2,
      pageTitle: "Education Page 3",
      contentHeading: "What are companies expected to do?",
      contentText: "In 2011 the United Nations adopted the UN Guiding Principles on Business and Human Rights (UNGPs), a global standard setting out the obligations and responsibilities of governments and businesses to respect human rights. The UNGPs also introduced the concept of human rights due diligence, a process considered as international best practice, to mitigate human rights risks. Based on the UNGPs, several countries have introduced new laws and regulations (see e.g. UK Modern Slavery Act) to hold companies accountable for the negative impact that their business activities may have on individuals and communities. In addition, the UN General Assembly adopted the 17 Sustainable Development Goals (SDGs) to fight poverty, inequality and injustice to create a more sustainable future. SDG 8.7 specifically sets out the aim to eradicate modern slavery. The private sector must actively contribute if the SDGs are to be realized. Companies can help meet the SDGs by engaging in modern slavery due diligence."
    },
    {
      pid: 3,
      pageTitle: "Education Page 4",
      contentHeading: "What is modern slavery due diligence?",
      contentText: "Modern slavery due diligence is a form of human rights due diligence which focuses solely on the risk of workers being exposed to modern slavery or slave-like working conditions. Modern slavery due diligence is the key methodology to effectively tackle slavery in global supply chains. The continuous risk mitigation process includes the:<ol><li>Identification of business activities causing or contributing to modern slavery</li><li>integration of the findings and implementation of an adequate risk treatment plan</li><li>monitoring the effectiveness of the adopted measures, and</li><li>public disclosure of the procedure</li></ol></ol>"
    },
   ]
};


export default config;
