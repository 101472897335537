import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";
import { withAuthorization, withEmailVerification } from "../lib/session";
import MobileStepper from '@material-ui/core/MobileStepper';
import education from "../data/education";
import DOMPurify from 'dompurify';

//const createDOMPurify = require('dompurify');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(8)
  },
  wrapper: {
    marginTop: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(3),
    //height: "420px"
  },
  title: {
    //padding: theme.spacing(4, 0),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    textAlign: 'left',
    letterSpacing: '2px'
  },
  subTitle: {
    //padding: theme.spacing(4, 0),
    color: 'lightgrey',
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '26px Roboto',
    fontWeight: 'bolder',
    fontStretch: 'condensed',
    textAlign: 'right',
    borderBottom: '2px solid',
    marginBottom: theme.spacing(1)
  },
  heading: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left',
    color: '#000000',
    opacity: 1,
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6)
  },
  content: {
    textAlign: 'justify',
    font: '14px/22px Roboto',
    fontWeight: 'lighter',
    letterSpacing: 0,
    color: '#0C0C0C',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  textPosition: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(0, 0.75),
    marginBottom: theme.spacing(2)
  },
  skip: {
    margin: theme.spacing(0, 0.75),
    marginBottom: theme.spacing(2),
  },
  stepper: {
    //marginLeft: 'auto',
    //marginRight: 'auto',
    maxWidth: "400px",
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontSize: '20px',
  },
  dot: {
    width: '15px',
    height: '15px',
    margin: "0 10px"
  },
  footer: {
    bottom: "250px",
    position: "fixed"
  }
}));

function EducationPage({ history, match }) {
  const classes = useStyles();
  var activeId;
  const content = education.sections.map(section => section);
  const { id } = match.params;
  //check :id isnumeric and within range or reset to 1
  (!isNaN(id) && (id <= content.length) && (id >= 1)) ?
    activeId = parseInt(id) :
    activeId = 1;
    // activeStep is 1 less because it is 0 indexed
    const activeStep = activeId - 1;
    const activeConfig = content[activeStep];

  //sanitize html import although securely stored and used with dangerouslySetInnerHTML
  //below
  var clean = DOMPurify.sanitize(activeConfig.contentText, {ALLOWED_TAGS: ['Strong', 'ol', 'li']});

  function handleNext() {
    const nextStep = activeId + 1;
    history.push(`/learn/${nextStep}`);
    // scroll to top
    window.scrollTo(0, 0);
  }

  function handleBack() {
    const previousStep = activeId - 1;
    history.push(`/learn/${previousStep}`);
  }

  function handleSkip() {
    history.push(`/form/1`);
  }

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
      <Grid container className={classes.wrapper} >
        <Grid container alignItems="flex-end" justify="space-between" >
          <Grid item xs={5}>
              <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              className={classes.title}
            >
              Risk Assessment Tool
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              className={classes.subTitle}
            >
              LEARN
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item >
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              classes={{
                dot: classes.dot, // class name, e.g. `classes-nesting-root-x`
              }}
              className={classes.stepper}
            />
          </Grid>
        </Grid>
      </Grid>
        {(activeId) > content.length ? (
          <React.Fragment>
            <Typography className={classes.heading}>
              {history.push(`/learn/${content.length}`)}
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Paper className={classes.card}>
              <Grid container >
                <Grid container>
                  <Grid item>
                    <Typography className={classes.heading}>
                      {activeConfig.contentHeading}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.content}
                      dangerouslySetInnerHTML={{ __html: clean }}
                    />
                  </Grid>
                </Grid>
                <Grid container justify={"space-between"}>
                  <Grid item >
                    <Button
                      className={classes.button}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={activeId === content.length}
                    >
                      Next
                    </Button>
                  </Grid>
                  <Grid item>
                  <Button
                    className={classes.skip}
                    color="primary"
                    onClick={handleSkip}
                  >
                    {activeId === content.length ? "Start assessment" : "Skip intro"}
                  </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </main>
  );
}

// prevent non-authorised users from accessing this page
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(EducationPage);
