import Typography from "@material-ui/core/Typography";
import React from "react";
import Card from '@material-ui/core/Card';

export default function ProductPopper({ product }) {
  switch (product) {
    case "option_product_nuts": {
      return (
        <Card elevation={0} >
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            brazil nuts/ chestnuts from the following countries carry a higher
            risk of being produced partly or entirely by forced or child labour:{" "}
            <strong>Bolivia, Peru</strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_bricks": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            bricks from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Afghanistan, Angola, Argentina, Bangladesh, Bolivia, Brazil,
              Burma, Cambodia, China, Colombia, Ecuador, India, Iran, Malawi,
              Myanmar, Nepal, North Korea, Pakistan, Paraguay, Peru, Russia,
              South Sudan, Uganda, Vietnam
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_carpets": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            carpets from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>Afghanistan, India, Iran, Nepal, Pakistan</strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_cattle": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            cattle from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Angola, Bolivia, Botswana, Brazil, Chad, Costa Rica, El Salvador,
              Ethiopia, Gabon, Kenya, Lesotho, Malawi, Mauritania, Namibia,
              Niger, Paraguay, South Sudan, Swaziland, Tanzania, Uganda, Zambia
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_coal": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            coal from the following countries carries a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              China, Colombia, Mongolia, North Korea, Pakistan, Ukraine
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_cocoa": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            cocoa from the following countries carries a higher risk of being
            produced partly or entirely by forced of child labour:{" "}
            <strong>
              Brazil, Cameroon, Cote d'Ivoire, Ghana, Guinea, Nigeria, Republic
              of Congo, Sierra Leone, Togo
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_cotton": {
      return (
        <Card elevation={0} >
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            cotton from the following countries carry a higher risk of being
            produced partly or entirely by forced of child labour:{" "}
            <strong>
              Argentina, Azerbaijan, Benin, Brazil, Burkina Faso, Cameroon,
              China, Egypt, India, Kazakhstan, Kyrgyzstan, Mali, Pakistan,
              Paraguay, Tajikistan, Togo, Turkey, Turkmenistan, Uzbekistan,
              Zambia
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_diamonds": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            diamonds from the following countries carry a higher risk of being
            produced partly or entirely by forced of child labour:{" "}
            <strong>
              Angola, Central African Republic, Democratic Republic of the
              Congo, Guinea, Liberia, Sierra Leone
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_electronics": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            electronics - laptops, computers, mobile phones from the following
            countries carry a higher risk of being produced partly or entirely
            by forced of child labour:{" "} <strong>China, Malaysia</strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_fish": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            fish from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Bangladesh, Belize, Brazil, Burundi, Cambodia, Cameroon, China,
              Cormoros, Costa Rica, El Salvador, Fiji, Gabon, Ghana, Iceland,
              India, Indonesia, Ireland, Israel, Jamaica, Japan, Kenya,
              Madagascar, Malaysia, Mauritius, Micronesia, Namibia, New Zealand,
              Nicaragua, Pakistan, Palau, Papua New Guinea, Paraguay, Peru,
              Philippines, Republic of Congo, Russia, Seychelles, Sierra Leone,
              Singapore, Solomon Island, South Africa, South Korea, Tanzania,
              Thailand, Taiwan, Uganda, United Kingdom, United States, Uruguay,
              Vietnam, Yemen
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_garments": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            garments - apparel & clothing from the following countries carry a
            higher risk of being produced partly or entirely by forced or child
            labour:{" "}
            <strong>
              Argentina, Bangladesh, Burma, Brazil, China, India, Malaysia,
              Thailand, Turkey, Vietnam
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_gold": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            Gold from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Bolivia, Burkina Faso, Burundi, Cameroon, Central African
              Republic, Colombia, Democratic Republic of the Congo, Ecuador,
              Ethiopia, Ghana, Guinea, Indonesia, Mali, Mongolia, Nicaragua,
              Niger, Nigeria, North Korea, Peru, Philippines, Senegal, Sudan,
              Suriname, Tanzania, Uganda
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_rice": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            rice from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Brazil, Burma, Dominican Republic, India, Kenya, Mali, Myanmar,
              Philippines, Uganda, Vietnam
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_sugarcane": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            sugarcane from the following countries carry a higher risk of
            being produced partly or entirely by forced or child labour:{" "}
            <strong>
              Belize, Bolivia, Burma, Brazil, Cambodia, Colombia, Dominican
              Republic, El Salvador, Guatemala, India, Kenya, Mexico, Pakistan,
              Paraguay, Philippines, Thailand, Uganda, Vietnam
            </strong>
          </Typography>
        </Card>
      );
    }
    case "option_product_timber": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            Based on research from the US Department of State and NGO Verité,
            timber from the following countries carry a higher risk of being
            produced partly or entirely by forced or child labour:{" "}
            <strong>
              Brazil, Cambodia, North Korea, Peru, Russia, Vietnam
            </strong>
          </Typography>
        </Card>
      );
    }
    default: {
      return null;
    }
  }
}
