import { makeStyles } from "@material-ui/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
  white: {
    backgroundColor: 'white',
    marginLeft: theme.spacing(2)
  },
  grid: {
    height: '100%'
  },
  paper: {
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  box: {
    marginTop: theme.spacing(3),
    display: "flex",
    border: '1px solid #4276DC',
    width: '900px',
    height: '167px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    //borderColor: blue[500],
    fontWeight: "bold",
    textAlign: "center",
    opacity: 1
  },
title: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '16px'
  },
text: {
    fontWeight: 'normal',
    textAlign: 'justify',
    marginRight: theme.spacing(8),
    fontSize: '14px',
    opacity: 1
  }
}));

export default function Disclaimer() {
  const classes = useStyles();

    return (
      <Box borderRadius="borderRadius" border={1} className={classes.box}>
        <Paper elevation={0} className={classes.paper} >
          <Grid container wraps='nowrap' alignItems='center' spacing={1}>
            <Grid item xs={1} className={classes.grid}>
              <Box bgcolor="primary.main" clone>
                <Avatar className={classes.white}>
                  <InfoOutlinedIcon fontSize='large' color='primary'/>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={11}  className={classes.grid}><Typography className={classes.title}>Important information</Typography></Grid>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={11} className={classes.grid}>
                <Typography className={classes.text}>
                Please note that this analysis provided by RightsDD does
                <strong> not</strong> constitute comprehensive modern slavery due
                diligence nor does it comprise legal advice. The information displayed within this dashboard is a
                high-level risk assessment and is provided to raise awareness of
                potential weaknesses within your organization’s own operations. RightsDD<i>now</i> is a starting point for ongoing due diligence.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Paper>
    </Box>
    );
}
