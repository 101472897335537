import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../img/logo.png";
import { GlobalStateContext } from "../lib/globalState";
import { AuthUserContext } from "../lib/session";
import SignOutButton from "./SignOutButton";

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
    //width: '100%',
    //margin: "auto auto"
  },
  toolbar: {
    flexWrap: "wrap",
  },
  logoWrapper: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  logo: {
    height: "auto",
    maxWidth: "100%"
  },
  navLeft: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  button: {
    margin: theme.spacing(1, 0.75)
  }
}));

function HeaderLogo() {
  const classes = useStyles();

  return (
    <div className={classes.logoWrapper}>
      <Link
        component={RouterLink}
        className={classes.link}
        underline="none"
        to="/"
      >
        <img
          className={classes.logo}
          src={logo}
          alt="RightsDD"
          height="57"
          width="206"
        />
      </Link>
    </div>
  );
}

function TopBarAuth() {
  const classes = useStyles();
  const [globalState] = useContext(GlobalStateContext);
  const { isDashboardGenerated } = globalState;

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <HeaderLogo />
        <nav className={classes.navLeft}>
          <Link
            component={RouterLink}
            className={classes.link}
            underline="none"
            to="/"
          >
            Start
          </Link>
          <Link
            component={RouterLink}
            className={classes.link}
            underline="none"
            to="/learn/1"
          >
            Learn
          </Link>
          {isDashboardGenerated ? (
            <React.Fragment>
              <Link
                component={RouterLink}
                className={classes.link}
                underline="none"
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                component={RouterLink}
                className={classes.link}
                underline="none"
                to="/due-diligence"
              >
                Due Diligence
              </Link>
            </React.Fragment>
          ) : (
            <Link
              component={RouterLink}
              className={classes.link}
              underline="none"
              to="/form/1"
            >
              Form
            </Link>
          )}
        </nav>
        <Link
          component={RouterLink}
          className={classes.link}
          underline="none"
          to="/account"
        >
          Account
        </Link>
        <SignOutButton />
      </Toolbar>
    </AppBar>
  );
}

function TopBarNonAuth() {
  const classes = useStyles();

  return (
    <AppBar position="fixed"  elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <HeaderLogo />
        <nav className={classes.navLeft}>
          <Link
            component={RouterLink}
            className={classes.link}
            underline="none"
            to="/"
          >
            Start
          </Link>
        </nav>
        <Button
          color="primary"
          component={RouterLink}
          to="/login"
          className={classes.button}
          underline="none"
        >
          Login
        </Button>
        <Button
          color="primary"
          component={RouterLink}
          to="/signup"
          variant="outlined"
          className={classes.button}
          underline="none"
        >
          Sign Up
        </Button>
      </Toolbar>
    </AppBar>
  );
}

function TopBar() {
  const authUser = useContext(AuthUserContext);

  return authUser ? <TopBarAuth /> : <TopBarNonAuth />;
}

export default TopBar;
