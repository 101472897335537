import { Link } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: "visible",
  },
  title: {
    paddingTop: theme.spacing(10),
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    marginLeft: "auto",
    marginRight: "auto",
  },
  blue: {
    color: '#508CEF'
  },
  strapline: {
    fontSize: '20px',
    fontWeight: 'lighter',
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3)
  },
  text: {
    fontSize: "16px",
    marginBottom: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "justify",
    minWidth: "320px",
    maxWidth: "900px",
  },
  grid: {
    height: '100vh',
  },
  dataMessage: {
    fontSize: "12px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
    textAlign: "justify",
    minWidth: "320px",
    maxWidth: "900px"
  }
}));

function HomePage() {
  const classes = useStyles();
  return (
    <main className={classes.root} >
      <Container maxWidth="md">
        <Grid container wrap="nowrap" className={classes.grid} direction="column" justify="space-between" alignItems="center" spacing={0}>
            <Grid item align="center" >
              <Typography
                className={classes.title}
                color="textPrimary"
              >
                RightsDD<sup className={classes.blue}>now</sup>
              </Typography>
              <Typography className={classes.strapline}>
                understand your company's slavery risks
              </Typography>
            </Grid>
            <Grid item  xs={10} >
              <Typography className={classes.text}>
                RightsDDnow is a free and easy-to-use risk management tool built to help companies assess
                their own business operations for modern slavery risks. Simply
                answer questions about your business to generate a comprehensive
                risk dashboard. RightsDD<sup className={classes.blue}>now</sup>
                {" "} red flags your high-risk areas and provides insights on modern
                slavery risk factors, relevant legislation and next steps.
              </Typography>
              <Typography className={classes.text}>
                There are 16 million humans working in conditions of modern slavery
                in global supply chains. To end this abuse, businesses need to take action
                {" "}now. If you are uncertain
                about what modern slavery is or how your company might be complicit
                in serious human rights abuses, click through our ‘Learn about
                modern slavery’ guidance.
              </Typography>
              <Grid container container  style={{justifyContent:"space-around"}}>
              <Grid item  >
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="primary"
                  to="learn/1"
                >
                  Learn about modern slavery
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="primary"
                  to="form/1"
                >
                  start risk assessment
                </Button>
              </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}  style={{flexBasis: "auto"}}>
            <Typography className={classes.dataMessage} gutterBottom>
                  <strong>About RightsDD<sup className={classes.blue}>now</sup>{" "}and your data</strong><br />
                  RightsDD<sup className={classes.blue}>now</sup> is intended
                  for, and access is limited to, organizations that wish to assess their
                  own operations for slavery risks. RightsDD reserves the right
                  to prevent or withdraw access to RightsDDnow at any point.
                  Please contact us at {" "}<Link color="primary" href="mailto:info@rightsdd.com?subject=Request for further information">info@rightsdd.com</Link>
                  {" "}if you have any questions or are interested in partnerships.
                  </Typography>
                  <Typography className={classes.dataMessage} >
                  RightsDD does not store your company risk profile data. This data
                  is deleted once you log out of RightsDD<sup className={classes.blue}>now</sup>.
                  RightsDD is committed to keeping your personal data secure. For further details
                  of our privacy policy, please click {" "}<Link href="https://www.rightsdd.com/privacy" >here</Link>.
              </Typography>
            </Grid>
          </Grid>
      </Container>
    </main>
  );
}

export default HomePage;
