import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
//import CustomPopper from "./CustomPopper";
//import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#000000",
    opacity: 1
  },
  listItem: {
    marginTop: theme.spacing(1),
  },
  cardstyle: {
    border: 0
  }
}));

export default function IndustryPopper({ industry }) {
  const classes = useStyles();

  switch (industry) {
    case "option_industry_domestic": {
      return (
        <Card elevation={0} >
          <Typography variant="body2">
            Domestic work is classified as a high-risk industry due to the
            following factors facilitating modern slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              Lack of protection by national labour laws in many jurisdictions
            </li>
            <li className={classes.listItem}>
              High proportion of vulnerable workers (e.g. women and migrant
              workers)
            </li>
            <li className={classes.listItem}>Low skilled workforce</li>
            <li className={classes.listItem}>
              Heavy workload (e.g. low wages, long hours, unpaid overtime, lack
              of healthcare coverage)
            </li>
            <li className={classes.listItem}>Short-term/seasonal contracts</li>
            <li className={classes.listItem}>
              Use of recruitment agencies (often resulting in illegal practices
              such as the charging of recruitment fees, the withholding of ID
              documents or wages)
            </li>
            <li className={classes.listItem}>Lack of grievance mechanisms</li>
          </ul>
        </Card>
      );
    }
    case "option_industry_construction": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The construction industry is classified as a high-risk industry due to the following factors facilitating modern slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>Low-skilled workforce</li>
            <li className={classes.listItem}>Short-term/seasonal contracts</li>
            <li className={classes.listItem}>Dangerous and unsafe working conditions</li>
            <li className={classes.listItem}>High proportion of vulnerable workers (e.g. migrant workers)</li>
            <li className={classes.listItem}>Frequent use of agents or other middlemen to recruit workers (often resulting in illegal practices such as the charging of recruitment fees, the withholding of identification documents or wages)</li>
          </ul>
        </Card>
      );
    }
    case "option_industry_agriculture": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The agriculture, forestry and fishing sector is classified as a
            high-risk industry due to the following factors facilitating modern
            slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              High proportion of short-term/ seasonal contract work
            </li>
            <li className={classes.listItem}>
              High proportion of vulnerable workers (e.g. migrant workers)
            </li>
            <li className={classes.listItem}>Low-skilled labour</li>
            <li className={classes.listItem}>
              High competitiveness resulting in long working hours and low wages
            </li>
            <li className={classes.listItem}>
              Use of recruitment agencies (often resulting in illegal practices
              such as charging of recruitment fees, withholding of wages,
              restriction of freedom of movement)
            </li>
            <li className={classes.listItem}>
              Particularly challenging work environment e.g. fishing in remote
              or illegal areas resulting in a lack of regulation and protection
              (fishing sector)
            </li>
          </ul>
        </Card>
      );
    }
    case "option_industry_hospitality": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The hospitality and food service industry is classified as a
            high-risk industry due to the following factors facilitating modern
            slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              Short-term/seasonal contract work{" "}
            </li>
            <li className={classes.listItem}>
              High proportion of vulnerable workers (women and migrant workers)
            </li>
            <li className={classes.listItem}>Lack of transparency</li>
            <li className={classes.listItem}>Use of recruitment agencies </li>
            <li className={classes.listItem}>Labour-intensive and low wages</li>
            <li className={classes.listItem}>High staff turnover</li>
            <li className={classes.listItem}>Lack of grievance mechanisms</li>
            <li className={classes.listItem}>
              Lack of social protection and health insurance
            </li>
          </ul>
        </Card>
      );
    }
    case "option_industry_apparel": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The apparel and textiles sector is classified as a high-risk
            industry due to the following factors facilitating modern slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>Low-skilled labour</li>
            <li className={classes.listItem}>High competitiveness </li>
            <li className={classes.listItem}>
              Labour intensive work and low wages
            </li>
            <li className={classes.listItem}>
              High proportion of vulnerable workers (mainly women and migrant
              workers)
            </li>
            <li className={classes.listItem}>
              Operations often in countries with weak labour laws and
              ineffective union representation
            </li>
            <li className={classes.listItem}>Use of recruitment agencies</li>
          </ul>
        </Card>
      );
    }
    case "option_industry_healthcare": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The healthcare service sector is classified as a high-risk industry
            due to the following factors facilitating modern slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>Labour intensive work</li>
            <li className={classes.listItem}>Low wages</li>
            <li className={classes.listItem}>
              High proportion of vulnerable workers
            </li>
            <li className={classes.listItem}>
              Use of recruitment agencies (often resulting in illegal practices
              e.g. withholding of ID documents or charging of recruitment fees)
            </li>
          </ul>
        </Card>
      );
    }
    case "option_industry_mining": {
      return (
        <Card elevation={0}>
          <Typography variant="body2">
            The mining and quarrying industry is classified as a high-risk
            industry due to the following factors facilitating modern slavery:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>Labour-intensive work</li>
            <li className={classes.listItem}>
              Poor health &amp; safety standards{" "}
            </li>
            <li className={classes.listItem}>Weak regulatory oversight</li>
            <li className={classes.listItem}>Low wages </li>
          </ul>
        </Card>
      );
    }
    default: {
      return null;
    }
  }
}
