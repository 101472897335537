import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "480px"
  }
}));

export default function AuthFormWrapper({ children }) {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
}
