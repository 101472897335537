import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { compose } from "recompose";
import CountryRisk from "../components/CountryRisk";
import DashboardHeader from "../components/DashboardHeader";
import EmploymentRisk from "../components/EmploymentRisk";
import IndustryRiskMain from "../components/IndustryRiskMain";
import ProductRiskMain from "../components/ProductRiskMain";
import PoliciesPracticesSummary from "../components/PoliciesPracticesSummary";
import ReportingObligations from "../components/ReportingObligations";
import { GlobalStateContext } from "../lib/globalState";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";
import { withAuthorization, withEmailVerification } from "../lib/session";
import Disclaimer from "../components/Disclaimer";
import countriesJson from "../data/countries.json";

const THRESHOLD_PREVALENCE = 4.107;
const THRESHOLD_GOVERNMENT_RESPONSE = -38.8214;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(-2),
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(4)
  },
  topPadding: {
      paddingTop: theme.spacing(10),
  },
  grid: {
    display: "grid",
    gridRowGap: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3)
  },
  footer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(8)
  }
}));

function DashboardPage() {
  const classes = useStyles();
  const [globalState] = useContext(GlobalStateContext);
  const { values } = globalState;

  const {
    S0001_companyName: companyName,
    S0002_registrationLocation: registrationLocation,
    S0003_globalTurnover: globalTurnover,
    S0004_globalHeadcount: globalHeadcount,
    S0005_usGovernmentContract: usGovernmentContract,
    S0006_countriesActive: countriesActive,
    S0007_riskyIndustries: riskyIndustries,
    S0008_riskyProducts: riskyProducts,
    S0009_manufactureProducts: manufactureProducts,
    S0010_domesticContractWorkers: domesticContractWorkers,
    S0011_foreignContractWorkers: foreignContractWorkers,
    S0012_recruitmentAgencies: recruitmentAgencies,
    S0013_codeOfConduct: codeOfConduct,
    S0014_forcedLabourPolicy: forcedLabourPolicy,
    S0015_recruitmentPolicy: recruitmentPolicy,
    S0016_modernSlaveryStatement: modernSlaveryStatement,
    S0017_inductionProgram: inductionProgram,
    S0018_generalTraining: generalTraining,
    S0019_speakUpSystem: speakUpSystem,
    S0020_speakUpQualities: speakUpQualities,
    S0021_internalAudits: internalAudits

  } = values;

  const isGBRegistered = registrationLocation === "option_country_GB";
  const isFRRegistered = registrationLocation === "option_country_FR";
  const isUSRegistered = registrationLocation === "option_country_US";
  const isAURegistered = registrationLocation === "option_country_AU";
  const hasGBOperations = countriesActive.includes("option_country_GB");
  const hasFROperations = countriesActive.includes("option_country_FR");
  const hasUSOperations = countriesActive.includes("option_country_US");
  const hasAUOperations = countriesActive.includes("option_country_AU");
  const hasUSGovernmentContract = usGovernmentContract === "Yes";

  const turnover = /option_turnover_([a-z])/.exec(globalTurnover)[1];
  const isOverGBTurnoverThreshold = turnover !== "a";
  const isOverAUTurnoverThreshold = turnover === "c" || turnover === "d";
  const headcount = /option_headcount_([sa-z])/.exec(globalHeadcount)[1];

  //set red lights: default lights to yellow (==0)
  var riskTrafficLights = [0, 0, 0, 0, 0, 0];
  //reporting obligations: light == orange
  if (
    (isGBRegistered || hasGBOperations) ||
    (isFRRegistered || hasFROperations) ||
    (isUSRegistered || hasUSOperations) ||
    (isAURegistered || hasAUOperations)
  )
    riskTrafficLights[0] = 1;

  //reporting obligations: light == red
  if (
    ((isGBRegistered || hasGBOperations) && isOverGBTurnoverThreshold) ||
    ((isFRRegistered || hasFROperations) && headcount === 'e') ||
    ((isUSRegistered || hasUSOperations) || hasUSGovernmentContract) ||
    ((isAURegistered || hasAUOperations) && isOverAUTurnoverThreshold)
  )
    riskTrafficLights[0] = 2;

  // geographic risk:
  // if country in countriesActive above prevalence and government response == red
  // if country in countriesActive above prevalence or government response == orange: else yellow
  var countriesActiveData = countriesJson.filter(c => countriesActive.includes(`option_country_${c.country_code}`));

  for (var i in countriesActiveData) {
    if (
      countriesActiveData[i].ms_prevalence_score > THRESHOLD_PREVALENCE &&
      countriesActiveData[i].ms_government_response_C > THRESHOLD_GOVERNMENT_RESPONSE) {
        riskTrafficLights[1] = 2;
    }
    else if (
      riskTrafficLights[1] === 0 &&
      (countriesActiveData[i].ms_prevalence_score > THRESHOLD_PREVALENCE ||
      countriesActiveData[i].ms_government_response_C > THRESHOLD_GOVERNMENT_RESPONSE)) {
        riskTrafficLights[1] = 1;
    }
  }

  // industry risk: red if more than 1 industry picked, orange if 1, else yellow
  if (
    riskyIndustries[0] !== "option_none"
  )
      riskTrafficLights[2] =
      (riskyIndustries.length > 1 ||
        manufactureProducts === 'Yes')
         ? 2
         : 1;

  // product risk == red if 1 product selected else yellow
  if (riskyProducts[0] !== "option_none") riskTrafficLights[3] = 2;
  if ((riskyProducts[0] === "option_none") && (manufactureProducts === "Yes"))
      {
        riskTrafficLights[3] = 1
      };

  //employment mode:
  if (
    (domesticContractWorkers === "Yes" && foreignContractWorkers === "Yes") ||
    ((domesticContractWorkers === 'Yes') || (foreignContractWorkers === 'Yes')) && recruitmentAgencies === 'Yes'
  ) {
    riskTrafficLights[4] = 2;
  } else if (
    domesticContractWorkers === "Yes" ||
    foreignContractWorkers === "Yes" ||
    recruitmentAgencies === "Yes"
  ) {
    riskTrafficLights[4] = 1;
  }

  //Policies and processes risk:  > red: 3 p/p == No, orange: 2/3 p/p == No, else yellow
  var policiesNoCount =+
    (codeOfConduct === 'No' ? 1 : 0) +
    (forcedLabourPolicy === 'No' ? 1 : 0) +
    (recruitmentPolicy === 'No' ? 1 : 0) +
    (modernSlaveryStatement === 'No' ? 1 : 0) +
    (inductionProgram === 'No' ? 1 : 0) +
    (generalTraining === 'No' ? 1 : 0) +
    (speakUpSystem === 'No' ? 1 : 0) +
    (internalAudits === 'No' ? 1 : 0);

  if (policiesNoCount > 3) {
    riskTrafficLights[5] = 2;
  } else if (policiesNoCount > 1) {
    riskTrafficLights[5] = 1;
  }

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="lg">
        {values ? (
          <React.Fragment>
            <div className={classes.grid}>
              <Disclaimer />
              <DashboardHeader data={values} risk={riskTrafficLights}/>
              <div className={classes.topPadding} id={'sectionReportingObligations'}><ReportingObligations data={values} risk={riskTrafficLights[0]}/></div>
              <div className={classes.topPadding} id={'sectionCountry'}><CountryRisk companyName={companyName} data={values} risk={riskTrafficLights[1]}/></div>
              <div className={classes.topPadding} id={'sectionIndustry'}><IndustryRiskMain data={values} risk={riskTrafficLights[2]}/></div>
              <div className={classes.topPadding} id={'sectionProduct'}><ProductRiskMain data={values} risk={riskTrafficLights[3]}/></div>
              <div className={classes.topPadding} id={'sectionEmployment'}><EmploymentRisk data={values} risk={riskTrafficLights[4]}/></div>
              <div className={classes.topPadding} id={'sectionPoliciesPractices'}><PoliciesPracticesSummary data={values} risk={riskTrafficLights[5]}/></div>
            </div>
            <div className={classes.footer}>
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                to="due-diligence"
              >
                Continue
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <Paper className={classes.content}>
            <Typography component="p" variant="body1" align="center">
              No data found. Please go back and submit the form again.
            </Typography>
          </Paper>
        )}
      </Container>
    </main>
  );
}

// prevent non-authorised users from accessing this page
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(DashboardPage);
