import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
//import CustomPopper from "./CustomPopper";
//import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: "#F6F6F6",
  },
  panelText: {
    backgroundColor: "#F6F6F6",
    fill: "#F6F6F6",
  },
  expansionText: {
    textAlign: 'justify',
    font: '14px/22px Roboto',
    fontWeight: 'lighter',
    letterSpacing: 0,
    color: '#0C0C0C',
    backgroundColor: "#F6F6F6",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  },
  list: {
    font: '14px/22px Roboto',
    fontWeight: 'lighter',
    letterSpacing: 0,
    color: '#0C0C0C',
    backgroundColor: "#F6F6F6",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },
  listitem: {
    //marginTop: theme.spacing(1),
    //marginLeft: theme.spacing(3),
    //paddingLeft: theme.spacing(3),
    //marginRight: theme.spacing(0)
  },
  cardstyle: {
    border: 0
  }
}));

export default function PolicyProcessText({ cardItem }) {
  const classes = useStyles();

  switch (cardItem) {
    case "codeOfConduct": {
      return (
        <Card className={classes.panelText} elevation={0} >
          <Typography className={classes.expansionText} >
            The adoption of a code of conduct is an essential foundation for
            protecting worker rights. A code of conduct should be aligned with
            the UN Guiding Principles of Business and Human Rights (UNGPs),
            should apply to the entire supply chain and should set out your
            organization’s overall commitment to respecting human rights and
            complying with modern slavery laws and regulations.
          </Typography>
        </Card>
      );
    }
    case "modernSlaveryPolicy": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            The prohibition of modern slavery can either be set out in a separate
            human rights policy or incorporated in the company’s code of conduct.
            A stand-alone human rights policy should emphasize your company’s
            commitment and signal a higher level of engagement. The policy should
            help organizations to clearly communicate their expectations to all
            business partners and stakeholders. At a minimum it should prohibit
            any direct or indirect association with modern slavery.
          </Typography>
        </Card>
      );
    }
    case "recruitmentPolicy": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Certain recruitment practices can facilitate modern slavery. A
            recruitment policy should address and prohibit all practices known
            to facilitate risks of labour exploitation. Key aspects to include
            are:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              Prohibition of charging workers recruitment fees or any other fees for their recruitment
            </li>
            <li className={classes.listItem}>
              Prohibition of withholding of identification documents
            </li>
            <li className={classes.listItem}>
              Contracts provided in a language that the worker understands
            </li>
            <li className={classes.listItem}>
              Where accommodation is provided, it should be adequate to the health and well-being of the employees
            </li>
            <li className={classes.listItem}>
              Prohibition of unreasonable constraints on the free movement of workers
            </li>
          </ul>
        </Card>
      );
    }
    case "modernSlaveryStatement": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Many companies are obliged to publish a Modern Slavery Statement
            (under the UK Modern Slavery Act and/or Australian Modern Slavery Act)
            annually. Public disclosure on the steps taken to mitigate modern
            slavery risks is an essential part of human rights due diligence.
            Apart from legal requirements, greater transparency is also
            increasingly requested by investors, consumers and employees.
          </Typography>
        </Card>
      );
    }
    case "inductionProgramme": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Induction training on modern slavery is important to help workers
            understand their rights and to help them identify when other
            workers’ rights may be being violated.
          </Typography>
        </Card>
      );
    }
    case "modernSlaveryTraining": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Conducting human rights training for all employees is an important
            risk mitigation practice. Employees, particularly personnel with
            recruitment and procurement responsibilities, need to have sufficient
            knowledge of risk indicators and signs of abuse in order to identify
            violations at an early stage. Training should be provided to all
            employees and conducted on a regular basis.
          </Typography>
        </Card>
      );
    }
    case "speakUp": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Enabling workers to raise concerns and report on rights abuses is an
            essential prerequisite for mitigating and managing risks. Speak up
            systems should be available to all employees, free of charge,
            independent, autonomous and available in a language(s) understood by
            the workers. Speak up systems can take various different forms but
            organizations should encourage an open culture where employees feel
            comfortable in raising concerns.
          </Typography>
        </Card>
      );
    }
    case "internalAudit": {
      return (
        <Card className={classes.panelText} elevation={0}>
          <Typography className={classes.expansionText}>
            Audits themselves are not a stand-alone solution to mitigate modern
            slavery, but if applied responsibly they provide a useful mechanism
            to assess the effectiveness of an organization’s policies and
            practices.
          </Typography>
        </Card>
      );
    }
    default: {
      return null;
    }
  }
}
