import countries from "./countries";

export const countriesOptions = countries.map(country => ({
  value: `option_country_${country.country_code}`,
  name: country.country_name
}));

export const turnoverOptions = [
  { value: "option_turnover_a", name: "<$44 million" },
  { value: "option_turnover_b", name: "$44 million - $67 million" },
  { value: "option_turnover_c", name: "$67 million - $100 million" },
  { value: "option_turnover_d", name: ">$100 million" }
];

export const headcountOptions = [
  { value: "option_headcount_a", name: "<100" },
  { value: "option_headcount_b", name: "100-999" },
  { value: "option_headcount_c", name: "1,000-4,999" },
  { value: "option_headcount_d", name: "5,000-9,999" },
  { value: "option_headcount_e", name: ">10,000" }
];

export const riskyIndustriesOptions = [
  { value: "option_none", name: "None of these apply" },
  { value: "option_industry_domestic", name: "Domestic work" },
  { value: "option_industry_construction", name: "Construction" },
  {
    value: "option_industry_agriculture",
    name: "Agriculture, forestries, and fishing"
  },
  {
    value: "option_industry_hospitality",
    name: "Hospitality and food service"
  },
  { value: "option_industry_apparel", name: "Apparel and textiles" },
  { value: "option_industry_healthcare", name: "Healthcare services" },
  { value: "option_industry_mining", name: "Mining and quarrying" }
];

export const riskyProductsOptions = [
  { value: "option_none", name: "None of these apply" },
  { value: "option_product_nuts", name: "Brazil Nuts/Chestnuts" },
  { value: "option_product_bricks", name: "Bricks" },
  { value: "option_product_carpets", name: "Carpets" },
  { value: "option_product_cattle", name: "Cattle" },
  { value: "option_product_coal", name: "Coal" },
  { value: "option_product_cocoa", name: "Cocoa" },
  { value: "option_product_cotton", name: "Cotton" },
  { value: "option_product_diamonds", name: "Diamonds" },
  {
    value: "option_product_electronics",
    name: "Electronics - Laptops, Computers, Mobile Phones"
  },
  { value: "option_product_fish", name: "Fish" },
  { value: "option_product_garments", name: "Garments - Apparel & Clothing" },
  { value: "option_product_gold", name: "Gold" },
  { value: "option_product_rice", name: "Rice" },
  { value: "option_product_sugarcane", name: "Sugarcane" },
  { value: "option_product_timber", name: "Timber" }
];

export const speakUpQualitiesOptions = [
  { value: "option_none", name: "None of these apply" },
  { value: "option_speakup_1", name: "Free of charge" },
  { value: "option_speakup_2", name: "Can be used anonymously" },
  {
    value: "option_speakup_3",
    name: "Provided in a language understood by every employee"
  }
];

const arrayToObject = (array, keyField, valueField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField];
    return obj;
  }, {});

const allOptions = [
  ...countriesOptions,
  ...turnoverOptions,
  ...headcountOptions,
  ...riskyIndustriesOptions,
  ...riskyProductsOptions,
  ...speakUpQualitiesOptions
];

export const optionsDictionary = arrayToObject(allOptions, "value", "name");
