import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { compose } from "recompose";
import AuthFormWrapper from "../components/AuthFormWrapper";
import PasswordChangeForm from "../components/PasswordChangeForm";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../lib/session";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(4, 0)
  }
}));

function AccountPage() {
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          align="center"
          color="textPrimary"
        >
          Account: {authUser.email}
        </Typography>
        <AuthFormWrapper>
          <PasswordChangeForm />
        </AuthFormWrapper>
      </Container>
    </main>
  );
}

// prevent non-authorised users from accessing this page
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
