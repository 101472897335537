import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ExternalLink from "./ExternalLink";
import icon from '../img/icon-usa.svg';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  list: {
    //paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    //textAlign: 'left',
      },
  listItem: {
    font: '14px/22px Roboto',
    marginLeft: theme.spacing(-3),
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left'
  },
  iconImage: {
    opacity: 1,
    marginLeft: theme.spacing(3),
  },
  iconImageDimmed: {
    opacity: 0.2,
    marginLeft: theme.spacing(3),
  },
  title: {
    font: 'Bold 14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    textAlign: 'left',
  },
  text: {
    marginLeft: 0,
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    marginBottom: theme.spacing(0),
    textAlign: 'justify',
    paddingTop: 0
  },
  textAdditional: {
    marginLeft: 0,
    font: '14px/22px Roboto',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(12),
    textAlign: 'justify',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    },
  expand: {
    transform: 'rotate(0deg)',
    padding: 0,
    marginLeft: theme.spacing(0),//marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  additionalInfo: {
    textAlign: "left",
    font: "16px/57px Roboto",
    letterSpacing: 0,
    color: "#DF452D",
    maxWidth: "300px",
    paddingRight: "16px",
    display: "flex",
    marginRight: theme.spacing(8),
  },
  cardContent: {
    paddingTop: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: theme.spacing(12),
  },
  collapse: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  }
}));

export default function ReportingObligationsUS({
  companyName,
  scenario,
  isUSRegistered,
  hasUSOperations,
  hasUSGovernmentContract
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };


  if (scenario === 2) return null;

  // outcome 1
  if (!isUSRegistered && !hasUSOperations && !hasUSGovernmentContract) {
    return (
      <React.Fragment>
      <div>
      <img src={icon} alt="US jurisdiction" width="50" className={classes.iconImageDimmed}/></div>
      <div><Typography className={classes.title}>
        United States
      </Typography>
      <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="US" /> or the <ExternalLink type="CA" />. It is
            encouraged however to implement due diligence measures to mitigate
            modern slavery risks within the company's supply chain.
          </Typography>
        </div>
      </React.Fragment>
    );
  }

  // outcome 2
  if (!isUSRegistered && !hasUSOperations && hasUSGovernmentContract) {
    return (
        <React.Fragment>
        <div>
        <img src={icon} alt="US jurisdiction" width="50" className={classes.iconImage}/></div>
        <div><Typography className={classes.title}>
          United States
        </Typography>
        <Grid container >
          <Grid item >
            <Typography className={classes.text} variant="body1">
            It looks like {companyName} is not subject to the{" "}
            <ExternalLink type="CA" /> but may, if it supplies goods (other than
            commercially available off-the-shelf items) procured outside of the
            United States or performs services outside of the United States to
            the Federal Government, be subject to <ExternalLink type="US" />{" "}
            52.222-50 (h). 52.222-50 (h) requires subject contractors to
            maintain and implement an anti-trafficking in persons compliance
            plan and report on their anti-trafficking in persons due diligence
            to the Federal Government.
          </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item className={classes.additionalInfo}>
            Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            </Grid>
            <Grid item>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.cardContent}>
                <Typography className={classes.list}>
                  In the event your due diligence process reveals reasonable
                  suspicion that trafficking in persons exists within your supply
                  chain, you are obliged to certify to your contracting officer
                  that you have taken appropriate remedial actions. For more
                  information see <ExternalLink type="US" />.
                </Typography>
              </CardContent>
            </Collapse>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  // outcome 3
  if ((isUSRegistered || hasUSOperations) && !hasUSGovernmentContract) {
    return (
        <React.Fragment>
          <div>
            <img src={icon} alt="US jurisdiction" width="50" className={classes.iconImage}/></div>
              <div>
                <Typography className={classes.title}>
                  United States
                </Typography>
                <Grid container >
                  <Grid item >
                    <Typography className={classes.text} variant="body1">
                      It looks like {companyName} is not subject to the reporting requirements in{" "}
                      <ExternalLink type="US" /> 52.222.50 (h) which only apply to contracts valued at less than $500k.
                      If however, {companyName} supplies to the Federal Government at a value below the $500k threshold,
                      {" "}{companyName} will still be subject to the various other trafficking in persons requirements
                      contained in the {" "}<ExternalLink type="CA" />. If your company is a retail seller or manufacturer,
                      it may be subject to the California Transparency in Supply Chains Act. The Act requires companies
                      to publish a supply chain disclosure to mitigate modern slavery risks.
                    </Typography>
                  </Grid>
                </Grid>
        <Grid container >
          <Grid item className={classes.additionalInfo}>
            Additional Information
            <CardActions >
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            </Grid>
            <Grid item>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.cardContent}>
                <Typography className={classes.list}>At a minimum the disclosure must include information on whether
                your company:</Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                  engages in verification of product supply chains to evaluate and
                  address risks of human trafficking and slavery. If the
                  verification is not conducted by a third party, the statement
                  should acknowledge this fact.
                  </li>
                  <li className={classes.listItem}>
                  conducts audits of its suppliers to assess compliance with your
                  company’s ethical standards. If the audits were not independent
                  and unannounced, the statement should acknowledge this fact.
                  </li>
                  <li className={classes.listItem}>
                  requires its direct suppliers to certify that materials
                  incorporated into the product comply with the laws regarding
                  slavery and human trafficking of the country or countries in
                  which they are doing business.
                  </li>
                  <li className={classes.listItem}>
                  maintains internal accountability standards and procedures for
                  employees or contractors failing to meet company standards
                  regarding slavery and trafficking.
                  </li>
                  <li className={classes.listItem}>
                  provides training on human trafficking and slavery to employees
                  and management who have direct responsibility for supply chain
                  management, particularly with respect to mitigating risks within
                  the supply chains of products.
                  </li>
                </ul>
              </CardContent>
            </Collapse>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  // outcome 4
  if ((isUSRegistered || hasUSOperations) && hasUSGovernmentContract) {
    return (
        <React.Fragment>
          <div>
            <img src={icon} alt="US jurisdiction" width="50" className={classes.iconImage}/></div>
              <div>
                <Typography className={classes.title}>
                  United States
                </Typography>
                <Grid container >
                  <Grid item >
                    <Typography className={classes.text} variant="body1">
                      {companyName} may be, if it is a retail seller or manufacturer,
                      subject to the <ExternalLink type="CA" />. Furthermore,{" "}
                      {companyName} may, if it supplies goods (other than commercially available off-the-shelf
                      items) procured outside of the United States to the Federal Government or performs services
                      outside of the United States for the Federal Government, be subject
                      to Federal Acquisition Regulation 52.222-50 (h)). 52.222-50 (h)
                      requires subject contractors to maintain and implement an
                      anti-trafficking in persons compliance plan and report on their
                      anti-trafficking in persons due diligence to the Federal Government.
                    </Typography>
                    </Grid>
                  </Grid>
                <Grid container >
                  <Grid item className={classes.additionalInfo}>
                    Additional Information
                      <CardActions >
                        <IconButton
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                          })}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                        <ExpandMoreIcon />
                        </IconButton>
                      </CardActions>
                  </Grid>
                  <Grid item>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent className={classes.cardContent}>
                  <Typography className={classes.text}>
                    In the event your due diligence process reveals reasonable
                    suspicion that trafficking in persons exists within your supply
                    chain, you are obliged to certify to your contracting officer that
                    you have taken appropriate remedial actions. For more information
                    see <ExternalLink type="US" />.
                  </Typography>
                  <Typography className={classes.textAdditional} >
                    Additionally, the <ExternalLink type="CA" /> requires companies to
                    publish a supply chain disclosure which at a minimum must include
                    information on whether your company:
                  </Typography>
                  <ul className={classes.list}>
                    <li className={classes.listItem}>
                    engages in verification of product supply chains to evaluate and
                    address risks of human trafficking and slavery. If the
                    verification is not conducted by a third party, the statement
                    should acknowledge this fact.
                    </li>
                    <li className={classes.listItem}>
                    conducts audits of its suppliers to assess compliance with your
                    company’s ethical standards. If the audits were not independent
                    and unannounced, the statement should acknowledge this fact.
                    </li>
                    <li className={classes.listItem}>
                    requires its direct suppliers to certify that materials
                    incorporated into the product comply with the laws regarding
                    slavery and human trafficking of the country or countries in
                    which they are doing business.
                    </li>
                    <li className={classes.listItem}>
                    maintains internal accountability standards and procedures for
                    employees or contractors failing to meet company standards
                    regarding slavery and trafficking.
                    </li>
                    <li className={classes.listItem}>
                    provides training on human trafficking and slavery to employees
                    and management who have direct responsibility for supply chain
                    management, particularly with respect to mitigating risks within
                    the supply chains of products.
                    </li>
                  </ul>
                </CardContent>
              </Collapse>
              </Grid>
            </Grid>
        </div>
      </React.Fragment>
    );
  }

  // should never happen
  return null;
}
