import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { ErrorMessage } from "formik";
import React from "react";

function FormField({
  name,
  label,
  helperText,
  type,
  autoComplete,
  values,
  errors,
  touched,
  onChange
}) {
  return (
    <FormControl
      autoComplete={autoComplete}
      type={type}
      margin="normal"
      fullWidth={true}
      required={true}
      error={errors[name] && touched[name] ? true : false}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        id={name}
        name={name}
        type={type}
        value={values[name]}
        onChange={onChange}
      />
      <ErrorMessage name={name}>
        {errorMessage => <FormHelperText>{errorMessage}</FormHelperText>}
      </ErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default FormField;
