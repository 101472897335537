import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { optionsDictionary } from "../data/options";
import ProductPopper from "./ProductPopper";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridRowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: 450
  },
  box: {
    borderBottom: `1px solid black`,
    borderTop: `1px solid black`,
    height: 30,
    position: "relative"
  },
  yellow: {
    backgroundColor: yellow[500]
  },
  orange: {
    backgroundColor: orange[500]
  },
  red: {
    backgroundColor: red[500]
  },
  arrow: {
    borderRight: `1px solid black`,
    borderTop: `1px solid black`,
    left: -2,
    height: 20,
    position: "absolute",
    top: 11,
    transform: "rotate(45deg) translateX(-50%)",
    width: 20
  },
  whiteArrow: {
    backgroundColor: "white"
  },
  yellowArrow: {
    backgroundColor: yellow[500]
  },
  orangeArrow: {
    backgroundColor: orange[500]
  },
  redArrow: {
    backgroundColor: red[500],
    left: "auto",
    right: -17
  },
  right: {
    textAlign: "right"
  },
  list: {},
  listItemInner: {
    alignItems: "center",
    display: "flex",
    fontSize: "1rem"
  },
  productRiskHeading: {
    marginLeft: theme.spacing(8),
    textAlign: "left",
    font: 'Normal 24px Roboto',
    letterSpacing: 0,
    color: "#DF452D",
    opacity: 1
  },
  productRisk: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(3),
    textAlign: "left",
    font: 'Normal 14px Roboto',
    //fontSize: "24px",
    letterSpacing: 0,
    opacity: 1
  }
}));

export default function ProductRisk({ riskyProducts }) {
  const classes = useStyles();

  if (riskyProducts[0] === "option_none") {
    return null;
  }

  return (
    <div>
          {riskyProducts.map(product => (
          <ExpansionPanel key={product.toString()} >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <div className={classes.productRiskHeading}>
                {optionsDictionary[product]}
            </div>
            </ExpansionPanelSummary>
          <ExpansionPanelDetails >
          <div className={classes.productRisk} >
            <ProductPopper product={product} />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        ))}
    </div>
  );
}
