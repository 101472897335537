import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import clsx from "clsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ReactTooltip from "react-tooltip";
import RiskQuadrant from "../components/RiskQuadrant";
import WorldMap from "../components/WorldMap";


const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(6),
    marginLeft: 0,
    width: '100%',
    //borderBottom: '1px solid #CCCCCC',
    opacity: 1
  },
  wrapperSummary: {
    marginLeft: 1,
  },
  container: {
    marginLeft: theme.spacing(3)
  },
  title: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto Condensed, sans-serif',
    font: '32px/43px Roboto',
    fontWeight: 'lighter',
    fontStretch: 'condensed',
    float: 'left',
    color: '#000000'
  },
  intro: {
    fontStyle: "normal",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'justify',
    marginRight: theme.spacing(6),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    fontSize: 'small',
    fontWeight: 'normal'
  },
  topBorder: {
    marginLeft: 0,
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
    opacity: 1
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  alignBottomCenter: {
    textAlign: 'center',
    fontSize: "12px",
    marginTop: theme.spacing(4)
  },
  alignBottomRight: {
    float: 'right',
    fontSize: "12px",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(4)
  },
  alignBottomLeft: {
    float: 'left',
    fontSize: "12px",
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(4)
  },
  alignUpperLeft: {
    float: 'left',
    fontSize: "12px",
    marginBottom: theme.spacing(2)
  },
  alignUpperRight: {
    float: 'right',
    fontSize: "12px",
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(2)
  },
  source: {
    fontSize: "12px",
    marginLeft: theme.spacing(9.5),
    marginTop: theme.spacing(4)
  },
  dot: {
    height: '30px',
    width: '30px',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1,
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
    //borderWidth: 3,
    //borderStyle: 'inset'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  riskQuadrant: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  tooltip: {
    fill: 'blue',
    color: 'red',
    backgroundColor: 'yellow'
  },
  extraClass: {
      font: "14px/19px Roboto",
      letterSpacing: 0,
      color: "#FFFFFF",
      borderRadius: "10px",
}
}));

export default function CountryRisk({ companyName, data, risk }) {
  const classes = useStyles();
  const { S0006_countriesActive: countriesActive } = data;
  const [content, setContent] = useState("");
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={expanded === false ? <RemoveIcon fontSize="large" fontWeight="bold"/> : <AddIcon fontSize="large" fontWeight="bold"/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleExpandClick}
      >
        <Grid container >
          <Grid container >
            <Grid item xs={1} >
              <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
            </Grid>
            <Grid item xs={11} >
              <Typography className={classes.title} component="h4" variant="h5">
                Your country risk
              </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={1}/><Grid item xs={11} >
              <Typography className={classes.intro} variant="body2">
                Modern Slavery is a complex and widespread form of human rights abuse. No country is free of
                slavery, but some countries have a higher prevalence of slavery than
                others. The following map shows the estimated number of slaves per 1,000 people in the countries in which {" "}{companyName}{" "}operates.  If you are operating in a higher risk country, you are
                encouraged to act with particular caution and implement effective due
                diligence to mitigate slavery risks.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <Grid container >
        <Grid item xs={12} className={classes.topBorder} />
        <Grid item xs={1}/><Grid item >
          <Typography className={classes.riskQuadrant} variant="subtitle2">
            Prevalence of modern slavery in countries where you have business operations
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <WorldMap
          countriesActive={countriesActive}
          setTooltipContent={setContent}
          />
          <ReactTooltip className={classes.extraClass} type="dark" border={false}>
            {content}
          </ReactTooltip>
        </Grid>
        <Grid item xs={12} className={classes.topBorder} />

      </Grid>
      <Grid container>
        <Grid item xs={1} /><Grid item xs={11}>
          <Typography className={classes.riskQuadrant} variant="subtitle2">
            Prevalence – regulation risk quadrant of your business operations
          </Typography>
        </Grid>
        <Grid item xs={1} /><Grid item xs={11}>
          <Typography className={classes.intro} variant="body2">
            Modern slavery risks are heightened if a country has a weak legal
            framework or governance to prevent abuses. The following quadrant
            shows prevalence of slavery and the strength of governance in the
            countries in which {companyName} operates. When prioritizing
            actions to address slavery risk, you are encouraged to take
            both the prevalence of modern slavery and the governance response
            index into consideration.
          </Typography>
        </Grid>
        <Grid item xs={1} /><Grid item xs={11}>
          <Typography className={classes.alignUpperLeft}>low prevalance, low regulation</Typography>
          <Typography className={classes.alignUpperRight}>high prevalance, low regulation</Typography>
          <RiskQuadrant countriesActive={countriesActive} />
          <p></p><Typography className={classes.alignBottomLeft}>low prevalance, high regulation</Typography>
          <Typography className={classes.alignBottomRight}>high prevalance, high regulation</Typography>
        </Grid>
        <Grid item className={classes.wrapper} >
          <Typography  className={classes.source}>
            Source: Prevalence and regulation data drawn from Global Slavery Index 2018 Dataset (Walk Free Foundation, available from: www.globalslaveryindex.org) and RightsDD research.
          </Typography>
        </Grid>
      </Grid>
    </ExpansionPanel>
  );
}
