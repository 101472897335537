import React, { useEffect, useMemo, useReducer } from "react";
import config from "../data/config";
import { generateEmptyState } from "./utils";

const GlobalStateContext = React.createContext();

const emptyState = generateEmptyState(config);

function reducer(state, action) {
  switch (action.type) {
    case "SAVE_VALUES": {
      const { payload } = action;
      const newValues = { ...state.values, ...payload.values };
      const newFormStep =
        payload.formStep > state.formStep ? payload.formStep : state.formStep;
      return {
        ...state,
        values: newValues,
        formStep: newFormStep
      };
    }
    case "GENERATE_DASHBOARD": {
      return {
        ...state,
        isDashboardGenerated: true
      };
    }
    case "RESET": {
      return action.payload;
    }
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}

const withGlobalState = Component => props => {
  const persistedState = JSON.parse(sessionStorage.getItem("persistedState"));
  // if data exists in sessionStorage use that instead of empty state
  const initialState = persistedState ? persistedState : emptyState;
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  // save state to sessionStorage
  useEffect(() => {
    sessionStorage.setItem(`persistedState`, JSON.stringify(state));
  }, [state]);

  return (
    <GlobalStateContext.Provider value={value}>
      <Component {...props} />
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, withGlobalState };
