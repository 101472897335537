import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import PolicyProcessText from "./PolicyProcessText";
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import { ReactComponent as IconConduct} from '../img/icon-conduct.svg';
import { ReactComponent as IconProcess} from '../img/icon-process.svg';
import { ReactComponent as IconStatement} from '../img/icon-statement.svg';
import { ReactComponent as IconPolicy} from '../img/icon-policy.svg';
import { ReactComponent as IconInduction} from '../img/icon-induction.svg';
import { ReactComponent as IconAudits} from '../img/icon-audits.svg';
import { ReactComponent as IconSpeakUp} from '../img/icon-speak.svg';
import { ReactComponent as IconChess} from '../img/icon-chess.svg';

const useStyles = makeStyles(theme => ({
  container: {
    height: "190px", //original 134px
    width: "900px",
    backgroundColor: "#F6F6F6",
    position: 'relative',
    borderLeft: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    padding: 0,
  },
  cBox: {
    height: "134px",
    fill: '#CCCCCC',
    color: "#303030",
    textAlign: 'center',
    font: '14px Roboto',
    letterSpacing: 0,
    width: "225px",
    backgroundColor: 0,
    position: 'relative',
    borderRadius: 0,
    border: "1px solid #CCCCCC",
    "&:hover": {
        fill: "#4276DC",
        color: "#4276DC",
      },
  },
  cBoxSelected: {
    height: "134px",
    width: "225px",
    backgroundColor: "#F6F6F6",
    fill: '#4276DC',
    color: "#4276DC",
    position: 'relative',
    textAlign: 'center',
    font: '14px Roboto',
    borderRadius: 0,
    //borderTop: "1px solid #CCCCCC",
    borderLeft: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
  },
  panelText: {
    backgroundColor: "#F6F6F6",
  },
  dot: {
    height: '22px',
    width: '22px',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: '50%',
    display: 'flex',
    border: '3px solid #FFFFFF',
    opacity: 1
  },
  dot_red: {
    backgroundColor: '#DF452D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_orange: {
    backgroundColor: '#DF8E2D',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
  dot_yellow: {
    backgroundColor: '#F1CA61',
    border: '3px solid #FFFFFF',
    opacity: 1,
    boxShadow: '0px 0px 5px #0000004F'
  },
}));
;
export default function ComplianceCard( { risk } ) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [cardText, setCardText] = useState("");
  const [boxColor, setBoxColor] = useState([0,0,0,0,0,0,0,0]);
  const handleBoxClick = (i, selected) => {
      switch(i) {
      case 0:
        selected === 0 ? setBoxColor([1,0,0,0,0,0,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
        setCardText('codeOfConduct')
        if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
        break;
        case 1:
          selected === 0 ? setBoxColor([0,1,0,0,0,0,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('modernSlaveryPolicy')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 2:
          selected === 0 ? setBoxColor([0,0,1,0,0,0,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('modernSlaveryStatement')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 3:
          selected === 0 ? setBoxColor([0,0,0,1,0,0,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('recruitmentPolicy')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 4:
          selected === 0 ? setBoxColor([0,0,0,0,1,0,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('inductionProgramme')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 5:
          selected === 0 ? setBoxColor([0,0,0,0,0,1,0,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('internalAudit')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 6:
          selected === 0 ? setBoxColor([0,0,0,0,0,0,1,0]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('modernSlaveryTraining')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        case 7:
          selected === 0 ? setBoxColor([0,0,0,0,0,0,0,1]) : setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('speakUp')
          if ((boxColor[i] === 1 && expanded) || !expanded) setExpanded(!expanded);
          break;
        default:
          setBoxColor([0,0,0,0,0,0,0,0]);
          setCardText('');
      }
  };

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item>
            <ExpansionPanel style={{borderTop: "1px solid #CCCCCC"}} elevation={0}  expanded={expanded}
            >
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Card
                    border={0}
                    raised={false}
                    className={boxColor[0] === 0 ? classes.cBox : classes.cBoxSelected}
                    onClick={() => handleBoxClick(0, boxColor[0])}
                  >
                    <div>
                      <span className={clsx(classes.dot, risk[0] === 2 ? classes.dot_red : classes.dot_yellow)} />
                      <div>
                        <IconConduct style={{transform: "translate(0, -50%)"}} />
                        <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                          Code of Conduct
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card
                    raised={false}
                    className={boxColor[1] === 0 ? classes.cBox : classes.cBoxSelected}
                    onClick={() => handleBoxClick(1, boxColor[1])}
                  >
                  <div>
                    <span className={clsx(classes.dot, risk[1] === 2 ? classes.dot_red : classes.dot_yellow)} />
                    <div>
                      <IconProcess style={{transform: "translate(0, -50%)"}} />
                      <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                        Modern Slavery Policy
                      </Typography>
                    </div>
                  </div>
                </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card
                    raised={false}
                    className={boxColor[2] === 0 ? classes.cBox : classes.cBoxSelected}
                    onClick={() => handleBoxClick(2, boxColor[2])}
                  >
                  <div>
                    <span className={clsx(classes.dot, risk[3] === 2 ? classes.dot_red : classes.dot_yellow)} />
                    <div>
                      <IconStatement style={{transform: "translate(0, -50%)"}} />
                      <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                        Modern Slavery Statement
                      </Typography>
                    </div>
                  </div>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card
                    raised={false}
                    className={boxColor[3] === 0 ? classes.cBox : classes.cBoxSelected}
                    onClick={() => handleBoxClick(3, boxColor[3])}
                  >
                  <div>
                    <span className={clsx(classes.dot, risk[2] === 2 ? classes.dot_red : classes.dot_yellow)} />
                    <div>
                      <IconPolicy style={{transform: "translate(0, -50%)"}} />
                      <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                        Recruitment Policy
                      </Typography>
                    </div>
                  </div>
                  </Card>
                </Grid>
              </Grid>
              <ExpansionPanelSummary className={classes.container}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <PolicyProcessText cardItem={cardText} />
              </ExpansionPanelSummary >
            </ExpansionPanel>
            </Grid>
            <Card
              raised={false}
              className={boxColor[4] === 0 ? classes.cBox : classes.cBoxSelected}
              onClick={() => handleBoxClick(4, boxColor[4])}
            >
            <div>
              <span className={clsx(classes.dot, risk[4] === 2 ? classes.dot_red : classes.dot_yellow)} />
              <div>
                <IconInduction style={{transform: "translate(0, -50%)"}} />
                <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                  Induction Program
                </Typography>
              </div>
            </div>
            </Card>
            <Card
              border={0}
              raised={false}
              className={boxColor[5] === 0 ? classes.cBox : classes.cBoxSelected}
              onClick={() => handleBoxClick(5, boxColor[5])}
            >
            <div>
              <span className={clsx(classes.dot, risk[7] === 2 ? classes.dot_red : classes.dot_yellow)} />
              <div>
                <IconAudits style={{transform: "translate(0, -50%)"}} />
                <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                  Internal Audits
                </Typography>
              </div>
            </div>
            </Card>
            <Card
              raised={false}
              className={boxColor[6] === 0 ? classes.cBox : classes.cBoxSelected}
              onClick={() => handleBoxClick(6, boxColor[6])}
            >
            <div>
              <span className={clsx(classes.dot, risk[5] === 2 ? classes.dot_red : classes.dot_yellow)} />
              <div>
                <IconChess style={{transform: "translate(0, -50%)"}} />
                <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                  Modern Slavery Training
                </Typography>
              </div>
            </div>
            </Card>
            <Card
              raised={false}
              className={boxColor[7] === 0 ? classes.cBox : classes.cBoxSelected}
              onClick={() => handleBoxClick(7, boxColor[7])}
            >
            <div>
              <span className={clsx(classes.dot, risk[6] === 1 ?
                classes.dot_orange :
                risk[6] === 2 ?
                classes.dot_red :
                classes.dot_yellow)} />
              <div>
                <IconSpeakUp style={{transform: "translate(0, -50%)"}} />
                <Typography style={{fontWeight: 'bold', transform: "translate(0, -50%)"}}>
                  Speak Up
                </Typography>
              </div>
            </div>
            </Card>
        </Grid>
      </React.Fragment>
    );
}
