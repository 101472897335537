import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import { makeStyles } from "@material-ui/styles";
import { scaleLinear, scaleSqrt } from "d3-scale";
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import countriesJson from "../data/countries.json";
import mapJson_v01 from "../data/world-110m-loc.json";
//import mapJson from "../data/world-50m-simplified.json";

const useStyles = makeStyles(theme => ({
  wrapper: {
    /*border: `1px solid ${grey[300]}`,*/
    height: "auto",
    width: "90%",
    paddingLeft: "10%"
  },
  circle: {
    hover: {opacity: 10},
  },
  text: {

  },
  tooltip: {
    fill: 'blue',
    color: 'red',
    backgroundColor: 'yellow'
  }
}));

const markerSizeScale = scaleSqrt()
  .domain([0, 8.214])
  .range([1, 15]);

const markerColourScale = scaleLinear()
  .domain([0, 8.214])
  .range([yellow[300], red[900]]);

  const geoUrl = mapJson_v01;
  //CDN version of above data source
  //"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export default function WorldMap({ countriesActive, setTooltipContent }) {
  const classes = useStyles();

  return (
    <ComposableMap
      className={classes.wrapper}
      data-tip=""
      projection="geoMercator"
      width={980}
      height={550}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geography, i) => (
            <Geography
              key={i}
              geography={geography}
              style={{
                default: {
                  fill: "#ECEFF1",
                  stroke: "#607D8B",
                  strokeWidth: 0,
                  outline: "none",
               },
               hover: {
                  /*fill: "#CFD8DC",*/
                  fill: "#ECEFF1",
                  stroke: "#607D8B",
                  strokeWidth: 0,
                  outline: "none",
               },
               pressed: {
                  fill: "#ECEFF1",
                  stroke: "#607D8B",
                  strokeWidth: 0,
                  outline: "none",                }
              }}
            />
          ))
        }
      </Geographies>
      {countriesJson
        .filter(country =>
          countriesActive.includes(`option_country_${country.country_code}`)
        )
        .filter(country => country.coordinates)
        .map((country, i) => (
          <Marker
            key={i}
            coordinates={country.coordinates}
            onMouseEnter={() => {
              setTooltipContent(
              `${
                    country.country_name
                  }, ${country.ms_prevalence_score.toFixed(
                    2
                  )} slaves per 1,000 of population`
                );
              }}
          onMouseLeave={() => {
          setTooltipContent("");
          }}
        style={{
              default: {
                fill: "none",
             },
             hover: {
                /*fill: "#CFD8DC",*/
                fill: "darkgrey",
                backgroundColor: 'red',
                borderRadius: "10px",
                borderColor: 'red',
             },
             pressed: {
                fill: "#FF5722",
                stroke: "#607D8B",
                strokeWidth: 1,
                outline: "none",                }
            }}
          >
            <circle className={classes.circle}
              cx={0}
              cy={0}
              r={markerSizeScale(country.ms_prevalence_score_C)}
              fill={markerColourScale(country.ms_prevalence_score_C)}
              opacity='1'
            />
            {/*}<circle
              cx={0}
              cy={0}
              r={markerSizeScale(country.ms_prevalence_score_C) - 6}
              fill='white'
            />*/}
            <text className={classes.text}
              textAnchor="middle"
              y={25}
              style={{
                fontFamily: "Verdana, sans-serif",
                fontSize: 12,
                background: 'black',
                borderColor: 'black'
              }}
            >
              {country.country_name}
            </text>
          </Marker>
        ))}
    </ComposableMap>
  );
}
