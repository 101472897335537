import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import manufacturingIcon from "../img/manufacturing.png";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  innerGrid: {
    display: "grid",
    gridColumnGap: theme.spacing(4),
    gridTemplateColumns: "min-content 1fr"
  },
  list: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
    font: "14px/22px Roboto",
    letterSpacing: 0,
    color: "#000000",
    opacity: 1
  },
  listitem: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  intro: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: theme.spacing(0),
  },
  image: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2)
  },
  normal: {
    font: "Normal 14px Roboto",
    color: "#000000"
  },
  section: {
    display: "flex",
    marginLeft: theme.spacing(12),
  },
  cardstyle: {
  },
  industryContainer: {
    paddingTop: theme.spacing(3),
    borderTop: '1px solid #CCCCCC',
  },
  title: {
    marginLeft: theme.spacing(0),
    textAlign: 'left',
    font: '24px/57px Roboto',
    letterSpacing: 0,
    color: '#000000'
  }
}));

export default function ManufacturingRisk({ manufactureProducts }) {
  const classes = useStyles();

  if (manufactureProducts === "No") {
    return null;
  }

  return (
    <Grid container className={classes.industryContainer}>
    <Card className={classes.cardstyle} elevation={0}>
    <Grid className={classes.section}>
      <Grid item xs={4} className={classes.title}>
        Manfacturing
      </Grid>
    </Grid>
    <Grid className={classes.section}>
        <Grid item xs={8} className={classes.intro}>
        <Typography className={classes.intro} variant="body1">
          You have stated that your company is involved in the manufacturing of
          products. Manufacturing may increase a company’s modern slavery risk,
          for example when all or some the following conditions exist:
        </Typography>
        <ul className={classes.list}>
          <li className={classes.listItem}>Low wages</li>
          <li className={classes.listItem}>
            Poor health & safety standards (e.g. due to work with hazardous
            chemicals, heavy work machinery or unsafe workspaces)
          </li>
          <li className={classes.listItem}>High competitiveness</li>
          <li className={classes.listItem}>
            Outsourcing of manufacturing to countries with weak labour standards
            and regulations
          </li>
          <li className={classes.listItem}>
            Unethical sourcing of raw materials
          </li>
        </ul>
      </Grid>
      <Grid item xs={4}>
      <div >
        <img className={classes.image} src={manufacturingIcon} alt="General Industry Icon" width="166" />
      </div>
      </Grid>
      </Grid>
      </Card>
    </Grid>
  );
}
