import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import AuthFormWrapper from "../components/AuthFormWrapper";
import PasswordResetForm from "../components/PasswordResetForm";
import ScrollToTopOnMount from "../lib/scrollToTopOnMount";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(4, 0)
  }
}));

function PasswordResetPage() {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <ScrollToTopOnMount />
      <Container maxWidth="md">
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          align="center"
          color="textPrimary"
        >
          Password Reset
        </Typography>
        <AuthFormWrapper>
          <PasswordResetForm />
        </AuthFormWrapper>
      </Container>
    </main>
  );
}

export default PasswordResetPage;
