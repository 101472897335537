import { makeStyles } from "@material-ui/styles";
import React from "react";
import agricultureIcon from "../img/agriculture.png";
import apparelIcon from "../img/apparel.png";
import constructionIcon from "../img/construction.png";
import domesticIcon from "../img/domestic.png";
import healthcareIcon from "../img/healthcare.png";
import hospitalityIcon from "../img/hospitality.png";
import miningIcon from "../img/mining.png";

const useStyles = makeStyles(theme => ({
  icon: {
    "&:not(:last-of-type)": {
      marginRight: theme.spacing(2)
    }
  }
}));

export default function IndustryIcon({ industry }) {
  const classes = useStyles();

  switch (industry) {
    case "option_industry_domestic": {
      return (
        <div className={classes.icon} key="option_industry_domestic">
          <img src={domesticIcon} alt="Domestic work" width="166" />
        </div>
      );
    }
    case "option_industry_construction": {
      return (
        <div className={classes.icon} key="option_industry_construction">
          <img
            src={constructionIcon}
            alt="Construction"
            width="220" />
        </div>
      );
    }
    case "option_industry_agriculture": {
      return (
        <div className={classes.icon} key="option_industry_agriculture">
          <img
            src={agricultureIcon}
            alt="Agriculture, forestries, and fishing"
            width="166"
          />
        </div>
      );
    }
    case "option_industry_hospitality": {
      return (
        <div className={classes.icon} key="option_industry_hospitality">
          <img
            src={hospitalityIcon}
            alt="Hospitality and food service"
            width="166"
          />
        </div>
      );
    }
    case "option_industry_apparel": {
      return (
        <div className={classes.icon} key="option_industry_apparel">
          <img src={apparelIcon} alt="Apparel and textiles" width="166" />
        </div>
      );
    }
    case "option_industry_healthcare": {
      return (
        <div className={classes.icon} key="option_industry_healthcare">
          <img src={healthcareIcon} alt="Healthcare services" width="166" />
        </div>
      );
    }
    case "option_industry_mining": {
      return (
        <div className={classes.icon} key="option_industry_mining">
          <img src={miningIcon} alt="Mining and quarrying" width="166" />
        </div>
      );
    }
    default: {
      return null;
    }
  }
}
