import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ReportingObligationsAU from "./ReportingObligationsAU";
import ReportingObligationsDefault from "./ReportingObligationsDefault";
import ReportingObligationsFR from "./ReportingObligationsFR";
import ReportingObligationsGB from "./ReportingObligationsGB";
import ReportingObligationsUS from "./ReportingObligationsUS";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
card: {
  maxWidth: 345,
},
media: {
  height: 0,
  paddingTop: '56.25%', // 16:9
},
expand: {
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
},
expandOpen: {
  transform: 'rotate(180deg)',
},
wrapper: {
    padding: theme.spacing(3)
  },
wrapperSummary: {
  maxHeight: 50,
  marginLeft: 1
},
column: {
  flexBasis: '5%',
},
helper: {
  borderLeft: '2px solid ${theme.palette.divider}',
  padding: theme.spacing(1, 2),
},
dot: {
  height: '30px',
  width: '30px',
  marginLeft: theme.spacing(0),
  marginTop: theme.spacing(2),
  borderRadius: '50%',
  display: 'flex',
  border: '3px solid #FFFFFF',
  opacity: 1,
},
dot_red: {
  backgroundColor: '#DF452D',
  border: '3px solid #FFFFFF',
  opacity: 1,
  boxShadow: '0px 0px 5px #0000004F'
  //borderWidth: 3,
  //borderStyle: 'inset'
},
dot_orange: {
  backgroundColor: '#DF8E2D',
  border: '3px solid #FFFFFF',
  opacity: 1,
  boxShadow: '0px 0px 5px #0000004F'
},
dot_yellow: {
  backgroundColor: '#F1CA61',
  border: '3px solid #FFFFFF',
  opacity: 1,
  boxShadow: '0px 0px 5px #0000004F'
},
title: {
  marginLeft: 0,
  marginTop: theme.spacing(1),
  fontFamily: 'Roboto Condensed, sans-serif',
  font: '32px/43px Roboto',
  fontWeight: 'lighter',
  fontStretch: 'condensed',
  float: 'left',
  color: '#000000'
},
intro: {
  fontStyle: "normal",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  textAlign: 'justify',
  marginRight: theme.spacing(4),
},
  grid: {
    alignItems: "left",
    display: "grid",
    gridRowGap: theme.spacing(5),
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: "auto auto",
    paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(4),
    borderTop: '1px solid #CCCCCC',
    opacity: 1
  },
  row: {
    display: "flex"
  },
  list: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  listItem: {
    marginTop: theme.spacing(1)
  },
  default: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    opacity: 1
  }
}));

export default function ReportingObligations({ data, risk }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
  setExpanded(!expanded);
  };
  const {
    S0001_companyName: companyName,
    S0002_registrationLocation: registrationLocation,
    S0003_globalTurnover: globalTurnover,
    S0004_globalHeadcount: globalHeadcount,
    S0005_usGovernmentContract: usGovernmentContract,
    S0006_countriesActive: countriesActive
  } = data;

  const isGBRegistered = registrationLocation === "option_country_GB";
  const isFRRegistered = registrationLocation === "option_country_FR";
  const isUSRegistered = registrationLocation === "option_country_US";
  const isAURegistered = registrationLocation === "option_country_AU";
  const hasGBOperations = countriesActive.includes("option_country_GB");
  const hasFROperations = countriesActive.includes("option_country_FR");
  const hasUSOperations = countriesActive.includes("option_country_US");
  const hasAUOperations = countriesActive.includes("option_country_AU");
  const hasUSGovernmentContract = usGovernmentContract === "Yes";
  const scenario =
    isGBRegistered ||
    isFRRegistered ||
    isUSRegistered ||
    isAURegistered ||
    hasGBOperations ||
    hasFROperations ||
    hasUSOperations ||
    hasAUOperations ||
    hasUSGovernmentContract
      ? 1
      : 2;
  const turnover = /option_turnover_([a-z])/.exec(globalTurnover)[1];
  const isOverGBTurnoverThreshold = turnover !== "a";
  const isOverAUTurnoverThreshold = turnover === "c" || turnover === "d";
  const headcount = /option_headcount_([a-z])/.exec(globalHeadcount)[1];

  return (
    <ExpansionPanel defaultExpanded>
    <ExpansionPanelSummary
      expandIcon={expanded === false ? <RemoveIcon fontSize="large" fontWeight="bold"/> : <AddIcon fontSize="large" fontWeight="bold"/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      onClick={handleExpandClick}
    >
      <Grid container >
        <Grid container >
          <Grid item xs={1} >
            <span className={clsx(classes.dot, risk === 2 ? classes.dot_red : risk === 1 ? classes.dot_orange : classes.dot_yellow)} />
          </Grid>
          <Grid item xs={11} >
            <Typography className={classes.title} component="h4" variant="h5">
          Your reporting obligations
          </Typography>
        </Grid>
      </Grid>
    <Grid container >
      <Grid item xs={1}/><Grid item xs={11} >
        <Typography className={classes.intro} variant="body2">
        New laws and regulations require companies to report publicly on the
        steps they are taking to address modern slavery in their business
        operations and supply chains. Based on the information you have provided, this section
        displays information on the legal reporting obligations your company may
        be subject to.
        </Typography>
      </Grid>
    </Grid>
    </Grid>
    </ExpansionPanelSummary >
      <div className={classes.grid}>
        <ReportingObligationsGB
          companyName={companyName}
          scenario={scenario}
          isGBRegistered={isGBRegistered}
          hasGBOperations={hasGBOperations}
          isOverGBTurnoverThreshold={isOverGBTurnoverThreshold}
        />
        <ReportingObligationsFR
          companyName={companyName}
          scenario={scenario}
          isFRRegistered={isFRRegistered}
          hasFROperations={hasFROperations}
          headcount={headcount}
        />
        <ReportingObligationsUS
          companyName={companyName}
          scenario={scenario}
          isUSRegistered={isUSRegistered}
          hasUSOperations={hasUSOperations}
          hasUSGovernmentContract={hasUSGovernmentContract}
        />
        <ReportingObligationsAU
          companyName={companyName}
          scenario={scenario}
          isAURegistered={isAURegistered}
          hasAUOperations={hasAUOperations}
          isOverAUTurnoverThreshold={isOverAUTurnoverThreshold}
        />
      </div>
      <div className={classes.default}>
        <ReportingObligationsDefault
          companyName={companyName}
          scenario={scenario}
        />
      </div>
    </ExpansionPanel>
  );
}
