import {
  countriesOptions,
  headcountOptions,
  riskyIndustriesOptions,
  riskyProductsOptions,
  speakUpQualitiesOptions,
  turnoverOptions
} from "./options";

const config = {
  sections: [
    {
      id: 1,
      title: "Tell us about your company",
      questions: [
        {
          id: 1,
          label: "What is the full name of your company?",
          name: "S0001_companyName",
          type: "text",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 2,
          label: "Where is your company registered?",
          name: "S0002_registrationLocation",
          type: "select",
          options: countriesOptions,
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 3,
          label:
            "What was the company's global turnover last year (US$ million)?",
          name: "S0003_globalTurnover",
          type: "select",
          options: turnoverOptions,
          helperText:
            "This information is required in order to asses your company’s legal reporting obligations.",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 4,
          label: "How many employees does your company have?",
          name: "S0004_globalHeadcount",
          type: "select",
          options: headcountOptions,
          helperText:
            "This information is required in order to asses your company’s legal reporting obligations.",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 5,
          label:
            "Does your company supply goods or services to the US Federal Government with a contact value of more than $500,000?",
          name: "S0005_usGovernmentContract",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        }
      ]
    },
    {
      id: 2,
      title: "Geography",
      questions: [
        {
          id: 6,
          label:
            "Please select all the countries where your company has business operations:",
          name: "S0006_countriesActive",
          type: "select",
          options: countriesOptions,
          multiple: true,
          helperText:
            "",
          initialValue: [],
          validationType: "array",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        }
      ]
    },
    {
      id: 3,
      title: "Industry & Product",
      questions: [
        {
          id: 7,
          label: "Do you operate in any of the following industry/industries?",
          name: "S0007_riskyIndustries",
          type: "select",
          options: riskyIndustriesOptions,
          multiple: true,
          initialValue: [],
          validationType: "array",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 8,
          label: "Do you provide any of the following products?",
          name: "S0008_riskyProducts",
          type: "select",
          options: riskyProductsOptions,
          multiple: true,
          initialValue: [],
          validationType: "array",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 9,
          label:
            "Does your company manufacture any of the products that you sell?",
          name: "S0009_manufactureProducts",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        }
      ]
    },
    {
      id: 4,
      title: "Employment",
      questions: [
        {
          id: 10,
          label:
            "Does your company employ any domestic contract workers (e.g. short-term, temporary or seasonal workers)?",
          name: "S0010_domesticContractWorkers",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 11,
          label:
            "Does your company employ foreign contract workers (e.g. migrant contract workers who travel from another country to work)?",
          name: "S0011_foreignContractWorkers",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 12,
          label: "Does your company use recruitment agencies to hire workers?",
          name: "S0012_recruitmentAgencies",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        }
      ]
    },
    {
      id: 5,
      title: "Policies & Practices",
      questions: [
        {
          id: 13,
          label:
            "Does your company have a code of conduct defining the ethical principles the company will uphold with regard to human rights of its own workers?",
          name: "S0013_codeOfConduct",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 14,
          label:
            "Does your company have a policy in place prohibiting any form of forced labour, child labour, debt bondage or human trafficking?",
          name: "S0014_forcedLabourPolicy",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 15,
          label:
            "Does your company have a recruitment policy in place which defines and requires ethical recruitment practices?",
          name: "S0015_recruitmentPolicy",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 16,
          label: "Has your company published a Modern Slavery Statement?",
          name: "S0016_modernSlaveryStatement",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 17,
          label:
            "Does your company have an induction program in place that addresses modern slavery?",
          name: "S0017_inductionProgram",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 18,
          label:
            "Does your company provide training that addresses modern slavery to employees?",
          name: "S0018_generalTraining",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 19,
          label:
            "Does your company have a speak up system in place for employees to raise concerns related to labour rights infringements?",
          name: "S0019_speakUpSystem",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 20,
          label: "Is your company's speak up system... (select any that apply)",
          name: "S0020_speakUpQualities",
          type: "select",
          options: speakUpQualitiesOptions,
          multiple: true,
          initialValue: [],
          validationType: "array",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        },
        {
          id: 21,
          label:
            "Does your company conduct internal audits of its own operations for modern slavery risk?",
          name: "S0021_internalAudits",
          type: "radio",
          initialValue: "",
          validationType: "string",
          validations: [
            {
              type: "required",
              params: ["Required"]
            }
          ]
        }
      ]
    }
  ]
};

export default config;
